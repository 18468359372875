import {
  Button,
  colors,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListItemButton,
} from "@mui/material";
import { colors as farmColor } from "../../common/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from "react-router-dom";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
interface Props {
  items: any[];
  buttonTitle?: string;
  onClickItem?(): void;
  status?: boolean;
}
export default function VerticalList({
  items,
  status,
  buttonTitle,
  onClickItem,
}: Props) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        paddingTop: 20,
        paddingBottom: 50,
        borderBottomColor: colors.grey[400],
        borderBottomStyle: "solid",
        borderBottomWidth: 4,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          padding: 2,
          paddingBottom: 10,
        }}
      >
        <div style={{ width: "75%", display: "flex" }}></div>

        {buttonTitle && (
          <div style={{ width: "25%", display: "flex", justifyContent: "end" }}>
            <Button
              style={{ borderRadius: 20 }}
              color={"secondary"}
              size="small"
              variant="outlined"
              onClick={() => navigate("/post")}
            >
              {" "}
              {buttonTitle}
            </Button>
          </div>
        )}
      </div>

      <ImageList
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {items?.map((item, i) => (
          <ImageListItem
            sx={{
              backgroundColor: farmColor.farm_gray10,
              borderRadius: 2,
            }}
            key={item.img}
          >
            <ListItemButton
              key={item.img}
              onClick={() => navigate("/detailsview", { state: item })}
              style={{ display: "block", padding: 0 }}
            >
              {status && (
                <ImageListItemBar
                  position={"top"}
                  subtitle={
                    item.status === "Approved"
                      ? "掲載中"
                      : item.status === "Rejected"
                      ? "修正待ち"
                      : item.status === "Reported"
                      ? "報告"
                      : "審査中"
                  }
                  sx={{
                    maxWidth: 170,
                    height: 24,
                    color: "#000",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    background:
                      item.status === "Approved"
                        ? farmColor.farm_green150
                        : item.status === "Rejected"
                        ? farmColor.farm_red10
                        : farmColor.farm_yellow10,
                  }}
                  actionPosition="left"
                  actionIcon={
                    <IconButton sx={{}} aria-label={`info about ${item.title}`}>
                      {item.status === "Approved" && (
                        <CheckCircleOutlineIcon
                          style={{ color: "#000", fontSize: 16 }}
                        />
                      )}
                      {item.status === "Rejected" && (
                        <HighlightOffIcon
                          style={{ color: "#000", fontSize: 16 }}
                        />
                      )}
                      {item.status === "Pending" ||
                        (item.status === "Reported" && (
                          <RemoveCircleOutlineIcon
                            style={{ color: "#000", fontSize: 16 }}
                          />
                        ))}
                    </IconButton>
                  }
                />
              )}
              {!status &&
                moment(item?.expiry_date).isSameOrAfter(moment(), "day") && (
                  <ImageListItemBar
                    position={"top"}
                    subtitle={"投票受付中"}
                    sx={{
                      maxWidth: 170,
                      height: 24,
                      color: "#000",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      background: farmColor.farm_green150,
                    }}
                    actionPosition="left"
                  />
                )}
              <div style={{ height: 110 }}>
                <img
                  style={{
                    height: 110,
                    width: 170,
                    borderWidth: 1,
                    borderColor: colors.grey[400],
                    borderStyle: "solid",
                    borderRadius: 10,
                    zIndex: 0,
                    objectFit: "contain",
                    backgroundColor: farmColor.farm_green100,
                  }}
                  // Transition={(props) => (
                  //   <Slide
                  //     direction={i % 2 === 0 ? "right" : "left"}
                  //     {...props}
                  //   />
                  // )}
                  // loader={<div style={{ background: "#888" }} />}
                  srcSet={
                    item?.images[0]?.path
                      ? `${
                          process.env.REACT_APP_AWS_PATH_BUCKET +
                          item?.images[0]?.path
                        }?w=150&fit=crop&auto=format&dpr=2 2x`
                      : require("../../assets/images/default.png")
                  }
                  src={
                    item?.images[0]?.path
                      ? `${
                          process.env.REACT_APP_AWS_PATH_BUCKET +
                          item?.images[0]?.path
                        }?w=150&fit=crop&auto=format&dpr=2 2x`
                      : require("../../assets/images/default.png")
                  }
                  alt={item.title}
                />
              </div>
              <div style={{ width: 150, margin: 2 }}>
                <p style={{ margin: 0 }} className="truncate">
                  {item?.title}
                </p>
              </div>
              <span style={{ display: "flex" }}>
                <BorderColorIcon
                  fontSize={"small"}
                  style={{ color: colors.grey[500] }}
                />
                {/* <FontAwesomeIcon
                  
                  size={"lg"}
                  icon={faEye}
                /> */}
                &nbsp;
                <span style={{ color: farmColor.farm_gray200, marginTop: -2 }}>
                  {item?.comments_count}&nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon
                    size={"lg"}
                    icon={faThumbsUp}
                    color={colors.grey[500]}
                  />
                  &nbsp; {item?.likes_count}
                </span>
              </span>
            </ListItemButton>
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
