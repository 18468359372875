import React, { useEffect, useLayoutEffect } from "react";
import "./App.css";
import { checkLoggedIn } from "./routing";
// import Home from "./pages/Home";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import IntroPage from "./pages/IntroPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colors } from "./common/colors";
import MyFarm from "./pages/MyFarm";
import DashboardPage from "./pages/DashboardPage";
import CreatePost from "./pages/CreatePost";
import Header from "./components/Header";
import Unauthorized from "./unauthorized";
import VotingSeeMorePage from "./pages/VotingSeeMorePage";
import Login from "./pages/loginUser";
import DetailsViewPage from "./pages/DetailsViewPage";
import AdminHome2 from "./pages/AdminHome";
import { AuthProvider } from "react-auth-verification-context";
// import authService from "./services/app-services/auth-service";
// import setAuthToken from "./services/axios-util/set-auth-token";
// import userAuth from "./util/auth-util";
import { Translator, Config } from "react-translator-component";
import { useSelector } from "react-redux";

import PostView from "./pages/PostView";
import { language_config } from "./common/constants/language_config";
import UserInfo from "./pages/UserInfo";
// import userAuth from "./util/auth-util";
// import setAuthToken from "./services/axios-util/set-auth-token";
// import authService from "./services/app-services/auth-service";
import { checkLoggedInAdmin } from "./routing_admin";
import CategoryListPage from "./pages/CategoryListPage";
import setAuthorizationToken from "./services/axios-util/set-authorization-token";
import SuggestionBox from "./pages/SuggestionBox";
import FacilitatorList from "./pages/FacilitatorList";
// import setAuthToken from "./services/axios-util/set-auth-token";
// home page not in use new design////
const HomePage = checkLoggedIn(VotingSeeMorePage);
const AdminHome = checkLoggedInAdmin(AdminHome2);
const PostViewPage = checkLoggedInAdmin(PostView);
const CategoryPage = checkLoggedIn(CategoryListPage);
// const EventPage = checkLoggedIn(EventListPage);
// const ReportPage = checkLoggedIn(ReportListPage);
const FacilitatorPage = checkLoggedIn(FacilitatorList);
const SuggestionPage = checkLoggedIn(SuggestionBox);
const PostCreatePage = checkLoggedIn(CreatePost);
const Dashboard = checkLoggedIn(DashboardPage);
const MyFarmPage = checkLoggedIn(MyFarm);
const VotingSeeMore = checkLoggedIn(VotingSeeMorePage);
const DetailsView = checkLoggedIn(DetailsViewPage);

function App() {
  Config.default = "ja";
  Config.list = language_config;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, user, isError } = useSelector(
    (state: any) => state.userAuth
  );
  useLayoutEffect(() => {
    // setAuthToken(
    //   "fU5DZc1HCdlBzfJZ5k9ECR7tU8a11685OWZ0J9T1QqWgqWvdbR538aOjGfP4SMpG"
    // );
    // const key = userAuth.isAuthorized();
    // if (key) {
    //   setAuthToken(key);
    // } else {
    //   // loginUser();
    // }
  }, []);
  useLayoutEffect(() => {
    // alert("here" + JSON.stringify(user));
    if (user?.id) {
      // alert("here" + user);
      setAuthorizationToken(user.auth_token);
      // setAuthToken(user.auth_token);
    }
  }, [user]);

  // const onWebViewCallback = (event: any) => {
  //   try {
  //     const d = JSON.parse(JSON.stringify(event.data));
  //     alert("d" + JSON.stringify(d));

  //     const data = JSON.parse(JSON.stringify(d));
  //     if (data.type === "WEBVIEW_CALLBACK") {
  //       alert(data.user);
  //       // Handle the callback data received from the WebView
  //       console.log("Received callback data from WebView:", data);

  //       // Optionally, trigger additional actions in your ReactJS app
  //     }
  //   } catch (error) {
  //     alert(error);
  //   }
  // };

  // Attach the event listener when the component mounts
  useEffect(() => {
    // window.addEventListener("message", onWebViewCallback);
    // // Cleanup the event listener when the component unmounts
    // return () => {
    //   window.removeEventListener("message", onWebViewCallback);
    // };
  }, []);

  // const loginUser = async () => {
  //   try {
  //     const user = await authService.login({
  //       username_or_email: "",
  //       password: "",
  //     });
  //     console.log("here,", user.key);
  //     setAuthToken(user.key);
  //     userAuth.loginUser(user);
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  // };

  console.log("here app");

  const theme = createTheme({
    typography: {
      fontFamily: "mplus-1c-regular",
    },
    palette: {
      primary: {
        main: colors.farm_green500,
        contrastText: "white",
      },
      secondary: {
        main: colors.farm_blue200,
        light: colors.farm_blue200,
        contrastText: "white",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            userSelect: "text",
          },
        },
      },
    },
  });

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Translator>
          <div className="App">
            <Router>
              <header>
                <Header />
              </header>
              <Routes>
                <Route path="/postview" element={<PostViewPage />} />
                <Route path="/adminhome" element={<AdminHome />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<IntroPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/user" element={<UserInfo />} />
                <Route path="/categories" element={<CategoryPage />} />
                <Route path="/detailsview" element={<DetailsView />} />
                <Route path="/facilitator" element={<FacilitatorPage />} />
                <Route path="/suggestion" element={<SuggestionPage />} />
                <Route path="/post" element={<PostCreatePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/myfarm" element={<MyFarmPage />} />
                <Route path="/votingseemore" element={<VotingSeeMore />} />

                <Route path="/unauthorized" element={<Unauthorized />} />

                <Route path="*" element={<NoPage />} />
              </Routes>
            </Router>
          </div>
        </Translator>
      </ThemeProvider>
    </AuthProvider>
  );
}
const NoPage = () => {
  return <h1>page not found 404</h1>;
};

export default App;

declare global {
  interface Window {
    PortalAPP: { GetPersonalBasicInfo: any };
    UploadImage: any;
    getAccessToken: any;
    GetAccessToken: any;
    getPersonalBasicInfo: any;
    portalAppGetAccessTokenCallBack: any;
  }
  // interface PortalApp {
  //   getAccessToken: any;
  //   GetAccessToken: any;
  //   getPersonalBasicInfo: any;
  //   portalAppGetAccessTokenCallBack: any;
  // }
}
