module.exports = {
  Home: "ホーム",
  Community: "コミュニティ",
  Event: "エベント",
  Report: "レポート",
  Post: "投稿",
  Dashboard: "ダッシュボード",
  MyCommunity: "マイコミュニティ",
  Vote_Ranking: "投票ランキング",
  see_more: "もっと見る",
  Group: "グループ",
  Sort: "並び替え",
  dead_linetxt: "期限内の投稿をすべて表示",
  Load_More: "さらにロード",
  Post_page: "投稿ページ",
  Post_Create_Des:
    "丁寧かつ分かりやすい投稿文を心掛けてください。不快や攻撃的な言葉使いなどは投稿を削除させて頂くことがあります。",
  Category: "カテゴリー",
  Title: "タイトル",
  Details: "本文",
  Tag: "タグ",
  Upload_Images: "画像をアップロード：最大5枚",
  upload_images_txt: "画像を選ぶ",
  Voting_Options: "投票内容",
  Vote_Option: "投票内容",
  fields_are_required: "* 必須項目",
  Add_voting_Option: "投票内容を追加する",
  Do_you_accept_comments: "コメントを受け付けますか？?",
  Yes: "はい",
  No: "いいえ",
  Would_you_like_to_set: "参加人数を設定しますか？",
  If_you_have_decided_on:
    "イベントなどの投稿で参加人数や定員数が決まっている場合は設定してください。",
  No_of_peoples: "いいえ",
  Post_period: "投稿期間",
  days: "日",
  Enter_other_Date: "日付を入力する",
  Do_you_want_to_post_anonymously: "匿名で投稿しますか？",
  Notes: "注意事項",
  This_is_not_targeted_slander:
    "ターゲットを絞った誹謗中傷の内容ではありません。",
  The_content_and_recruitment:
    "わいせつ行為や犯罪を促進する内容及び募集ではありません。",
  I_am_responsible: "投稿内容に責任を持ちます。",
  I_have_reviewed_the: "上記の注意事項を確認し、すべての事項に同意致します。.",
  Post_History: "投稿履歴",
  Pieces: "件",
  Comment_History: "コメント履歴",
  Number_of_Likes_Received: "受け取った「いいね！」の数",
  Posts: "投稿:",
  Comments: "コメント",
  Questionnaire: "アンケート",
  Question_title: "質問のタイトル",
  Posts_that_have_been:
    "多くのユーザーから投票があった投稿をランキング形式で表示します。",
  No_data_to_show: "表示可能なデータなし",
  This_is_a_place_where_users:
    "ユーザーが気になること、知りたいこと、伝えたいことを自由に投稿できる場所です。",
  Please_feel_free_to_post:
    "気になること、知りたいこと、伝えたいこと、何でもお気軽に投稿してください。",
  Please_feel_free_to_post_about_events:
    "行ってみた、食べてみた、体験してみた、などのレポートを自由にと横行してください。",
  New: "投稿日が新しい順",
  Old: "投稿日が古い順",
  High_Votes: "投票が多い順",
  Less_Votes: "投票が少ない順",
  Clear_Filter: "フィルターリセット",
  Please_feel_free_to_post_reports:
    "行ってみた、食べてみた、体験してみた、などのレポートを自由にと横行してください。",
  Mebuku_Community: "めぶくコミュニティ",
  Post_date: "投稿日",
  Approve: "承認",
  Reject: "拒否",
  Are_you_sure_to_approved_this_post: "この投稿内容を承認しますか？",
  Are_you_sure_to_rejected_this_post: "この投稿内容を拒否しますか？",
  Cancel: "キャンセル",
  Would_you_like_to_cast_this_vote_anonymously: "匿名で投票しますか?",
  If_you_vote_anonymously: "匿名で投票する場合、ニックネームは表されません。",
  Voting_completed_Would_you_like_to_add_a_comment:
    "投票完了。コメントを追記しますか。",
  Your_nickname_will_be_displayed_differently:
    "匿名か非匿名かでニックネームの表示が異なります。",
  Add_comment: "コメントを追加",
  Please_be_considerate_of_others:
    "相手の事を考え丁寧なコメントを心掛けてください。不快や攻撃的な言葉使いなどはコメントを削除させて頂くことがあります。",
  Edit_Comment: "コメントを編集",
  Using: "使っている.",
  Edit_Post: "投稿を編集",
  Summary: "[サマリー]",
  Vote: "投票",
  Votes: "投票",
  Voting_end_date: "投票最終日:",
  View_voting_status: "投票状況を見る",
  I_love_using_this_space_very_comfortable_to_work:
    "このスペースを使うのが大好きです！ カフェポップがBGMに流れているので仕事の邪魔にならず、とても快適に仕事ができます。 正直、飲み物や軽食の自動販売機があれば嬉しいです。",
  I_would_like_to_use_it_if_the_price:
    "もう少し値段が安かったら利用したいです。",
  I_use_it_occasionally:
    "時々使います。 子どものお迎えまでのちょっとした時間に利用できるのが嬉しいですね。 ただし、午前中に行くと少し料金が高くなってしまうかもしれません。 これから1階の無印良品で何か買うと割引かクーポンがもらえると嬉しいです。",
  Create_Group: "グループを追加",
  Add_New_Group: "グループを追加",
  Users: "ユーザー",
  Groups: "グループ",
  PendingPosts: "未投稿内容",
  Dashboard_Content: "ダッシュボード",
  Create_New_User: "ユーザーを追加",
  First_Name: "名",
  Last_Name: "氏",
  Email: "eメール",
  Password: "パスワード",
  Confirm_Password: "パスワード確認",
  Create: "作成",
  Edit_Group_Name: "グループを編集",
  Save: "保存",
  Group_Name: "グループ名",
  Pending_Posts: "未投稿内容",
  Next: "つぎへ",
  login: "ログイン",
  UserName: "ユーザー名",
  post_created: "投稿作成完了！",
  go_back: "戻る",
  create_group: "グループを作る",
  Are_You_Sure: "実行してもよろしいですか？",
  Do_you_really_want_to_APPROVE_this_post:
    "この投稿を承諾してもよろしいですか？この操作は取り消すことができません。",
  Do_you_really_want_to_REJECT_this_post:
    "この投稿を拒否してもよろしいですか？この操作は取り消すことができません。",
  Your_post_created: "投稿が作成されました。",
  we_found_negative_words:
    "投稿内容に使用できない言葉が含まれているようです。レビュー後に公開されます。",
  Your_vote_added: "投票が追加されました。",
  Your_comment_added: "コメントが追加されました。",
  Pool: "プール",
  Suggestions: "ご意見",
  Refresh: "リフレッシュ",
  This_comment_is_not_publicly_visible: "コメントはまだ投稿されていません。",
  In_Session: "開催中",
  End_of_page: "終了",
  Author: "オーサー",
  Session_timeout_Please_log_in_again:
    "セッションがタイムアウトしました。再度ログインしてください。",
};
