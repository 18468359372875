import { Box, Button, SelectChangeEvent, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DropDown from "../components/DropDown";
import "../App.css";
import VerticalList from "../components/lists/VerticalList";
import { styles } from "./styles";
import LoadingModal from "../components/LoadingModal";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { getGroups } from "../redux/Reducers/groupReducer";
import { getCategoryPosts } from "../redux/Reducers/postReducer";
import { T } from "react-translator-component";
import { getCategory } from "../redux/Reducers/categoriesReducer";
import { colors } from "../common/colors";

export default function CategoryListPage() {
  const [communityPost, setCommunityPosts] = useState<any>([]); //using now for all categories
  const [filteredPost, setFilteredPosts] = useState<any>([]);
  const { groups } = useSelector((state: any) => state.groups);
  const { categories } = useSelector((state: any) => state.categories);
  const [sortedCategories, setSortedCategories] = useState<any>([]);
  // const [current_page, setCurrentPage] = useState(0);
  const { categoryPosts, categoryPostsPagination, isLoadingCategoryPosts } =
    useSelector((state: any) => state.posts);
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState("desc");
  const DESC = [
    "気になること、知りたいこと、伝えたいことなど、自由に投稿してください。",
    "イベントや行事やお祭りなど、大きな集まりから小さな集まりまで自由に投稿してください。",
    "行ってみた、食べてみた、体験してきた、などのレポートを自由に投稿してください。",
  ];
  const dispatch = useAppDispatch();
  useEffect(() => {
    // dispatch(resetCategoryPosts());
    apiCalls();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (categoryPostsPagination?.current_page)
  //     setCurrentPage(categoryPostsPagination?.current_page);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [categoryPostsPagination]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const apiCalls = async () => {
    setLoader(true);
    await dispatch(getGroups());
    await dispatch(getCategory());
    let data = { categoryId: "", page: 1, order: order };
    await dispatch(getCategoryPosts(data));
    setLoader(false);
  };
  useEffect(() => {
    if (categoryPostsPagination?.current_page > 1) {
      const newItems = [...communityPost, ...categoryPosts];
      console.log(newItems);
      if (newItems) setCommunityPosts(newItems);
    } else {
      setCommunityPosts(categoryPosts);
      // setFilteredPosts(categoryPosts);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryPosts]);

  useEffect(() => {
    if (categories?.length > 0) {
      const temp = [...categories].sort(
        (a: { id: number }, b: { id: number }) =>
          a.id < b.id ? -1 : a.id > b.id ? 1 : 0
      );
      setSortedCategories(temp);
      // setSelectedCategory(temp[0]);
    }
  }, [categories]);

  const [category, setSelectedCategory] = useState<any>({
    value: "",
    name: sortedCategories[0]?.name,
  });
  const [group, setSelectedGroup] = useState<any>({
    value: "",
    name: "Group",
  });
  const [sort, setSort] = useState<any>({
    value: "",
    name: "Sort",
  });

  const handleSelectGroup = (event: SelectChangeEvent) => {
    const v = {
      value: event.target.value,
      name: event.target.value === "" ? "Group" : event.target.name,
    };
    setSelectedGroup(v);
  };
  const handleSelectCategory = (event: SelectChangeEvent) => {
    const v = {
      value: event.target.value,
      name: event.target.value === "" ? "Category" : event.target.name,
    };
    setSelectedCategory(v);
    setCommunityPosts([]);
    dispatch(getCategoryPosts({ categoryId: v.value, page: 1 }));
  };
  const handleSort = (event: SelectChangeEvent) => {
    console.log(event.target);

    const v = {
      value: event.target.value,
      name: event.target.value === "" ? "Sort" : event.target.name,
    };
    if (v.value.toString() === "2") {
      setOrder("asc");

      let data = { categoryId: "", page: 1, order: "asc" };
      dispatch(getCategoryPosts(data));
    } else if (v.value.toString() === "1") {
      setOrder("desc");
      let data = { categoryId: "", page: 1, order: "desc" };
      dispatch(getCategoryPosts(data));
    }
    setSort(v);
  };

  const renderList = useMemo(() => {
    if (filteredPost?.length === 0) return;
    console.log(sort.value);
    if (sort.value === 4) {
      const temp = [...filteredPost].sort((a: any, b: any) =>
        a.comments?.length > b.comments?.length ? 1 : -1
      );

      return <VerticalList items={temp} />;
    } else if (sort.value === 3) {
      const temp = [...filteredPost].sort((a: any, b: any) =>
        a.comments?.length < b.comments?.length ? 1 : -1
      );

      return <VerticalList items={temp} />;
    } else if (sort.value === 2) {
      const temp = [...filteredPost].sort((a: any, b: any) =>
        a.id > b.id ? 1 : -1
      );

      return <VerticalList items={temp} />;
    } else if (sort.value === 1) {
      const temp = [...filteredPost].sort((a: any, b: any) =>
        a.id < b.id ? 1 : -1
      );
      return <VerticalList items={temp} />;
    } else {
      return <VerticalList items={filteredPost} />;
    }
  }, [filteredPost, sort]);

  useEffect(() => {
    if (group.value !== "") {
      const filtered = communityPost.filter(
        (item: any) => item?.group?.id === group.value
      );
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts(communityPost);
    }
  }, [group, communityPost]);

  const loadMore = () => {
    if (categoryPostsPagination?.next_page > 0) {
      let data = {
        categoryId: category?.value,
        page: categoryPostsPagination?.next_page,
        order: order,
      };
      dispatch(getCategoryPosts(data));
    }
  };

  return (
    <div style={{ ...styles.communityContainer, marginTop: 80 }}>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 16,
          display: "flex",
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.farm_green500,
            padding: 1,
            paddingLeft: 4,
            paddingRight: 4,
            borderRadius: 2,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            borderColor: colors.farm_pink10,
            borderWidth: 2,
            borderStyle: "solid",
          }}
        >
          <span
            style={{
              fontSize: 24,
              fontFamily: "mplus-1c-regular",
              paddingLeft: 16,
              paddingRight: 16,
              color: "white",
            }}
          >
            みんなの投稿
          </span>
        </Box>
      </div>

      <DropDown
        title={T("Category")}
        value={category.value}
        options={sortedCategories}
        onChange={handleSelectCategory}
      />
      <p
        style={{
          ...styles.communiTitleDescrip,
          textAlign: "center",
          marginBottom: 0,
        }}
      >
        {DESC[category.value - 1]}
      </p>

      <DropDown
        title={T("Group")}
        value={group.value}
        options={
          groups?.length > 0
            ? [{ value: "", name: T("Clear_Filter") }, ...groups]
            : []
        }
        onChange={handleSelectGroup}
      />
      <DropDown
        title={T("Sort")}
        value={sort.value}
        options={[
          { value: 1, name: T("New") },
          { value: 2, name: T("Old") },
          { value: 3, name: "コメントが多い順" },
          { value: 4, name: "コメントが少ない順" },
        ]}
        onChange={handleSort}
      />

      {renderList}

      <LoadingModal
        visible={loader || isLoadingCategoryPosts}
        handleClose={() => console.log("here")}
      />
      {categoryPostsPagination?.next_page > 0 && (
        <Stack
          sx={{ width: "100%", color: "grey.500", justifyContent: "center" }}
          spacing={2}
        >
          <Button onClick={loadMore} size="small">
            {T("Load_More")}
          </Button>
        </Stack>
      )}
    </div>
  );
}
