import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  colors,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import React from "react";
import { styles } from "../pages/styles";
interface Props {
  item: any;
  index: number;
  onClickItem({ item }: any): void;
}

export default function SeeMoreSmall({ item, index, onClickItem }: Props) {
  return (
    <ListItemButton onClick={() => onClickItem(item)}>
      <div style={{ width: "100%" }}>
        <span>
          <FontAwesomeIcon
            size={"lg"}
            icon={faTrophy}
            color={colors.yellow[800]}
          />
          <span style={{ fontFamily: "mplus-1c-bold" }}> {index + 1}</span>
        </span>
        <ListItem
          alignItems="flex-start"
          sx={{ m: 0, p: 0, marginTop: -1, marginBottom: 2 }}
        >
          <ListItemAvatar>
            <img
              style={{
                ...styles.seeMoreImgSmall,
                borderColor: colors.grey[50],
                objectFit: "contain",
              }}
              alt="Remy Sharp"
              src={
                item?.images[0]?.path
                  ? process.env.REACT_APP_AWS_PATH_BUCKET +
                    item?.images[0]?.path
                  : require("../assets/images/default.png")
              }
            />
          </ListItemAvatar>
          <ListItemText
            sx={{
              paddingLeft: 1,
            }}
            primary={
              <React.Fragment>
                <span
                  style={{ fontFamily: "mplus-1c-bold" }}
                  className="truncate"
                >
                  {item.title}
                </span>
              </React.Fragment>
            }
            secondary={
              <div style={styles.seeMoreSec}>
                <span
                  style={{
                    ...styles.seeMoreEventTxt,
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                >
                  {`[ ${item?.category?.name} ]`}
                </span>
                <span style={{ display: "flex" }}>
                  <BorderColorIcon
                    fontSize={"small"}
                    style={{ color: colors.grey[500] }}
                  />
                  {item?.comments_count}&nbsp;&nbsp;
                  <FontAwesomeIcon
                    size={"lg"}
                    icon={faThumbsUp}
                    color={colors.grey[500]}
                  />
                  &nbsp; {item?.likes_count}
                </span>
              </div>
            }
          />
        </ListItem>
      </div>
    </ListItemButton>
  );
}
