import React, { useEffect, useMemo, useState } from "react";
import { BarChart, Bar, Cell, CartesianGrid, XAxis } from "recharts";

const Chart = (props: any) => {
  const { data, color } = props;

  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.9); // Set initial width to 90% of window width

  useEffect(() => {
    // Update chart width on window resize
    const handleResize = () => {
      setChartWidth(window.innerWidth * 0.9); // Update chart width to 90% of window width
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const getPath = (x: number, y: number, width: number, height: number) => {
  //   const radius = 32; // Set the border radius for the top corners
  //   return `
  //     M${x},${y + height}
  //     L${x},${y + radius}
  //     Q${x},${y} ${x + radius},${y}
  //     L${x + width - radius},${y}
  //     Q${x + width},${y} ${x + width},${y + radius}
  //     L${x + width},${y + height}
  //     Z
  //   `;
  // };
  // const getPath = (
  //   x: number,
  //   y: number,
  //   width: number,
  //   height: number,
  //   borderRadius: number,
  //   applyBottomCurve: boolean
  // ) => {
  //   const topRadius = borderRadius;
  //   const bottomRadius = applyBottomCurve ? borderRadius : 0;

  //   return `
  //       M${x + topRadius},${y}
  //       L${x + width - topRadius},${y}
  //       Q${x + width},${y} ${x + width},${y + height - bottomRadius}
  //       L${x},${y + height - bottomRadius}
  //       Q${x},${y} ${x + topRadius},${y}
  //       Z
  //     `;
  // };

  // const RoundedTopBar: FunctionComponent<any> = (props: any) => {
  //   const { fill, x, y, width, height } = props;
  //   return (
  //     <path
  //       d={getPath(x, y, width, height, 26, false)}
  //       stroke="none"
  //       fill={fill}
  //     />
  //   );
  // };

  const chartComponent = useMemo(
    () => (
      <BarChart
        width={chartWidth}
        height={300}
        data={data}
        margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="jp_name" />
        <Bar
          dataKey="count"
          fill="#8884d8"
          // shape={<RoundedTopBar />}
          label={{ position: "top", fill: "#000000" }}
        >
          {data?.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={color} />
          ))}
        </Bar>
      </BarChart>
    ),
    // eslint-disable-next-line
    [data, color]
  );

  return chartComponent;
};

export default Chart;

// import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
// import { BarChart, Bar, Cell, CartesianGrid, XAxis, YAxis } from "recharts";

// const Chart: FunctionComponent<any> = (props) => {
//   const { data, color } = props;

//   const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.9);

//   useEffect(() => {
//     const handleResize = () => {
//       setChartWidth(window.innerWidth * 0.9);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const getPath = (x: number, y: number, width: number, height: number) => {
//     const radius = 32;
//     return `
//       M${x},${y + height}
//       L${x},${y + radius}
//       Q${x},${y} ${x + radius},${y}
//       L${x + width - radius},${y}
//       Q${x + width},${y} ${x + width},${y + radius}
//       L${x + width},${y + height}
//       Z
//     `;
//   };

//   const RoundedTopBar: FunctionComponent<any> = (props) => {
//     const { fill, x, y, width, height } = props;
//     return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
//   };

//   const yDomain: [number, "auto"] = [0, "auto"];

//   const chartComponent = useMemo(
//     () => (
//       <BarChart
//         width={chartWidth}
//         height={300}
//         data={data}
//         margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="jp_name" />
//         <YAxis domain={yDomain} />
//         <Bar
//           dataKey="count"
//           fill="#8884d8"
//           shape={<RoundedTopBar />}
//           label={{ position: "top", fill: "#000000" }}
//         >
//           {data?.map((entry: any, index: number) => (
//             <Cell key={`cell-${index}`} fill={color} />
//           ))}
//         </Bar>
//       </BarChart>
//     ),
//     [data, color, chartWidth]
//   );

//   return chartComponent;
// };

// export default Chart;
