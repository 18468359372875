import { useEffect, useState } from "react";

import { Stack, Button } from "@mui/material";
import { colors } from "../common/colors";
import { T } from "react-translator-component";
import Chart from "../components/Chart";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { getDashboard } from "../redux/Reducers/dashboardReducer";
import LoadingModal from "../components/LoadingModal";

export default function DashboardPage() {
  const dispatch = useAppDispatch();
  const { isLoading, postTypeOne, postTypeZero } = useSelector(
    (state: any) => state.dashboard
  );
  const [selectBar, setSelectedBar] = useState("community");
  const [selectOpinionBar, setSelectedOpinionBar] = useState("community");

  const handleSelected = (bar: string) => {
    setSelectedBar(bar);
  };
  const handleSelectedOpinion = (bar: string) => {
    setSelectedOpinionBar(bar);
  };

  useEffect(() => {
    dispatch(getDashboard());
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ padding: 10, marginTop: 80 }}>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p> 意見ひろば</p>
        <Stack direction="row" spacing={2} sx={{ marginBottom: 4 }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.farm_green200,
              borderRadius: "20px",
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: colors.farm_green150,
            }}
            onClick={() => handleSelected("community")}
          >
            {T("Community")}
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.farm_blue200,
              borderRadius: "20px",
            }}
            onClick={() => handleSelected("event")}
          >
            {"イベント"}
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.farm_yellow800,
              borderRadius: "20px",
            }}
            onClick={() => handleSelected("report")}
          >
            {T("Report")}
          </Button>
        </Stack>
        {postTypeZero?.length ? (
          <Chart
            data={
              selectBar === "community"
                ? postTypeZero[0].data
                : selectBar === "event"
                ? postTypeZero[1].data
                : postTypeZero[2].data
            }
            color={
              selectBar === "community"
                ? colors.farm_green200
                : selectBar === "event"
                ? colors.farm_blue200
                : colors.farm_yellow800
            }
          />
        ) : (
          <div style={{ height: 200 }}>
            <LoadingModal
              visible={isLoading}
              handleClose={() => console.log("here")}
            />
          </div>
        )}

        <p> みんなの投稿</p>
        <Stack direction="row" spacing={2} sx={{ marginBottom: 4 }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.farm_green200,
              borderRadius: "20px",
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: colors.farm_green150,
            }}
            onClick={() => handleSelectedOpinion("community")}
          >
            {T("Community")}
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.farm_blue200,
              borderRadius: "20px",
            }}
            onClick={() => handleSelectedOpinion("event")}
          >
            {"イベント"}
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.farm_yellow800,
              borderRadius: "20px",
            }}
            onClick={() => handleSelectedOpinion("report")}
          >
            {T("Report")}
          </Button>
        </Stack>
        {postTypeOne?.length ? (
          <Chart
            data={
              selectOpinionBar === "community"
                ? postTypeOne[0].data
                : selectOpinionBar === "event"
                ? postTypeOne[1].data
                : postTypeOne[2].data
            }
            color={
              selectOpinionBar === "community"
                ? colors.farm_green200
                : selectOpinionBar === "event"
                ? colors.farm_blue200
                : colors.farm_yellow800
            }
          />
        ) : (
          <div style={{ height: 200 }}>
            <LoadingModal
              visible={isLoading}
              handleClose={() => console.log("here")}
            />
          </div>
        )}
      </div>
    </div>
  );
}
