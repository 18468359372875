import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import appServices from "../../services/app-services/appServices";

const initialState: any = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  post: [],

  approvedPost: [],
  approvePagination: {},
  isLoadingApproved: false,

  community: [],
  communityPagination: {},
  isLoadingCommunity: false,

  events: [],
  eventsPagination: {},
  isLoadingEvents: false,

  reports: [],
  reportsPagination: {},
  isLoadingReports: false,

  categoryPosts: [],
  categoryPostsPagination: {},
  isLoadingCategoryPosts: false,

  opinionPosts: [],
  opinionPostsPagination: {},
  isLoadingOpinionPosts: false,

  topPosts: [],
  topPostsPagination: {},
  isLoadingTopPosts: false,
};

export const getAllPosts = createAsyncThunk<any, number>(
  "posts/all",
  async (page, thunkAPI) => {
    try {
      return await appServices.getPosts();
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getApprovedPosts = createAsyncThunk<any, number>(
  "posts/approved",
  async (page, thunkAPI) => {
    try {
      return await appServices.getApprovedPosts(page);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTopPosts = createAsyncThunk<any, number>(
  "posts/getTopPosts",
  async (page, thunkAPI) => {
    try {
      return await appServices.getTopPosts(page);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getApprovedCommunityPosts = createAsyncThunk<any, number>(
  "posts/community",
  async (page, thunkAPI) => {
    try {
      return await appServices.getApprovedCommunityPosts(page);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getApprovedEventsPosts = createAsyncThunk<any, number>(
  "posts/events",
  async (page, thunkAPI) => {
    try {
      return await appServices.getApprovedEventsPosts(page);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getApprovedReportsPosts = createAsyncThunk<any, number>(
  "posts/reports",
  async (page, thunkAPI) => {
    try {
      return await appServices.getApprovedReportsPosts(page);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCategoryPosts = createAsyncThunk(
  "posts/categoryPosts",
  async (body: any, thunkAPI) => {
    try {
      return await appServices.getApprovedCategoryPosts(
        body.categoryId,
        body.page,
        body.order
      );
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getOpinionPosts = createAsyncThunk(
  "posts/OpinionPosts",
  async (data: any, thunkAPI) => {
    try {
      return await appServices.getOpinionPosts(data.page, data.order);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postReducer = createSlice({
  name: "post",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetCommunityPosts: (state, action) => {
      state.community = [];
      state.communityPagination = {};
      state.isLoadingCommunity = false;
    },
    resetPosts: (state) => {
      state.post = [];
    },

    resetEventsPosts: (state) => {
      state.events = [];
      state.eventsPagination = {};
      state.isLoadingEvents = false;
    },
    resetReportsPosts: (state) => {
      state.reports = [];
      state.reportsPagination = {};
      state.isLoadingReports = false;
    },
    resetCategoryPosts: (state) => {
      state.categoryPosts = [];
      state.categoryPostsPagination = {};
      state.isLoadingCategoryPosts = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.post = action.payload.posts;
      })
      .addCase(getAllPosts.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getApprovedPosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApprovedPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.approvedPost = action.payload.posts;
      })
      .addCase(getApprovedPosts.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getApprovedCommunityPosts.pending, (state) => {
        state.isLoadingCommunity = true;
      })
      .addCase(getApprovedCommunityPosts.fulfilled, (state, action) => {
        state.isLoadingCommunity = false;
        state.isSuccess = true;
        state.community = action.payload.posts;
        state.communityPagination = action.payload.pagination;
      })
      .addCase(getApprovedCommunityPosts.rejected, (state: any, action) => {
        state.isLoadingCommunity = false;
        state.isError = true;
      })
      .addCase(getApprovedEventsPosts.pending, (state) => {
        state.isLoadingEvents = true;
      })
      .addCase(getApprovedEventsPosts.fulfilled, (state, action) => {
        state.isLoadingEvents = false;
        state.isSuccess = true;
        state.events = action.payload.posts;
        state.eventsPagination = action.payload.pagination;
      })
      .addCase(getApprovedEventsPosts.rejected, (state: any, action) => {
        state.isLoadingEvents = false;
        state.isError = true;
      })

      .addCase(getApprovedReportsPosts.pending, (state) => {
        state.isLoadingReports = true;
      })
      .addCase(getApprovedReportsPosts.fulfilled, (state, action) => {
        state.isLoadingReports = false;
        state.isSuccess = true;
        state.reports = action.payload.posts;
        state.reportsPagination = action.payload.pagination;
      })
      .addCase(getApprovedReportsPosts.rejected, (state: any, action) => {
        state.isLoadingReports = false;
        state.isError = true;
      })
      .addCase(getOpinionPosts.pending, (state) => {
        state.isLoadingOpinionPosts = true;
      })
      .addCase(getOpinionPosts.fulfilled, (state, action) => {
        state.isLoadingOpinionPosts = false;
        state.isSuccess = true;
        state.opinionPosts = action.payload.posts;
        state.opinionPostsPagination = action.payload.pagination;
      })
      .addCase(getOpinionPosts.rejected, (state: any, action) => {
        state.isLoadingOpinionPosts = false;
        state.isError = true;
      })
      .addCase(getCategoryPosts.pending, (state) => {
        state.isLoadingCategoryPosts = true;
      })
      .addCase(getCategoryPosts.fulfilled, (state, action) => {
        state.isLoadingCategoryPosts = false;
        state.isSuccess = true;
        state.categoryPosts = action.payload.posts;
        state.categoryPostsPagination = action.payload.pagination;
      })
      .addCase(getCategoryPosts.rejected, (state: any, action) => {
        state.isLoadingCategoryPosts = false;
        state.isError = true;
      })
      .addCase(getTopPosts.pending, (state) => {
        state.isLoadingTopPosts = true;
      })
      .addCase(getTopPosts.fulfilled, (state, action) => {
        state.isLoadingTopPosts = false;
        state.isSuccess = true;
        state.topPosts = action.payload.posts;
        state.topPostsPagination = action.payload.pagination;
      })
      .addCase(getTopPosts.rejected, (state: any, action) => {
        state.isLoadingTopPosts = false;
        state.isError = true;
      });
  },
});

// export const { reset, resetPosts } = postReducer.actions;
// export const postReducer = createSlice({
//   name: "post",
//   initialState,
//   reducers: {
//     setPost: (state, action) => {
//       state.post = action.payload;
//     },
//     setCommunity: (state, action) => {
//       state.community = action.payload;
//     },
//     setEvent: (state, action) => {
//       state.event = action.payload;
//     },
//     setReport: (state, action) => {
//       state.report = action.payload;
//     },
//   },
// });

export const {
  reset,
  resetPosts,
  resetCommunityPosts,
  resetEventsPosts,
  resetReportsPosts,
  resetCategoryPosts,
} = postReducer.actions;

export default postReducer.reducer;
