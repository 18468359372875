module.exports = {
  Home: "Home",
  Community: "Community",
  Event: "Event",
  Report: "Report",
  Post: "Post",
  Dashboard: "Dashboard",
  MyCommunity: "MyCommunity",
  Vote_Ranking: "Vote Ranking",
  see_more: "see more",
  Group: "Group",
  Sort: "Sort",
  dead_linetxt: "All submissions within the deadline will be displayed below.",
  Load_More: "Load More",
  Post_page: "Post page",
  Post_Create_Des:
    "Please keep your posts polite and easy to understand. Posts that use offensive or offensive language may be deleted.",
  Category: "Category",
  Title: "Title",
  Details: "Details",
  Tag: "Tag",
  Upload_Images: "Upload images: maximum 5",
  upload_images_txt: "upload images",
  Voting_Options: "Voting Options",
  Vote_Option: "Vote Option",
  fields_are_required: "* fields are required.",
  Add_voting_Option: "Add voting Option",
  Do_you_accept_comments: "Do you accept comments?",
  Yes: "Yes",
  No: "No",
  Would_you_like_to_set: "Would you like to set the number of participants?",
  If_you_have_decided_on:
    "If you have decided on the number of participants or capacity for posting an event, etc., please set it.",
  No_of_peoples: "No: of peoples",
  Post_period: "Post period",
  days: "days",
  Enter_other_Date: "Enter other Date: (Up to 2 years.)",
  Do_you_want_to_post_anonymously: "Do you want to post anonymously?",
  Notes: "Notes",
  This_is_not_targeted_slander: "This is not targeted slander.",
  The_content_and_recruitment:
    "The content and recruitment do not promote obscene acts or crimes.",
  I_am_responsible: "I am responsible for the content posted.",
  I_have_reviewed_the:
    "I have reviewed the above notes and agree to all of them.",
  Post_History: "Post History",
  Pieces: "Pieces",
  Comment_History: "Comment History",
  Number_of_Likes_Received: "Number of Likes Received",
  Posts: "Posts:",
  Comments: "Comments",
  Questionnaire: "Questionnaire",
  Question_title: "Question title",
  Posts_that_have_been:
    "Posts that have been voted by many users are displayed in a ranking format.",
  No_data_to_show: "No data to show.",
  This_is_a_place_where_users:
    "This is a place where users can freely post anything they are curious about, want to know, or want to convey.",
  Please_feel_free_to_post:
    "Please feel free to post anything you care about, anything you want to know, anything you want to convey.",
  Please_feel_free_to_post_about_events:
    "Please feel free to post about events, functions, festivals, etc., from large gatherings to small gatherings.",
  New: "New",
  Old: "Old",
  High_Votes: "High Votes",
  Less_Votes: "Less Votes",
  Clear_Filter: "Clear Filter",
  Please_feel_free_to_post_reports:
    "Please feel free to post reports about what you've been to, eaten, experienced, etc.",
  Mebuku_Community: "Mebuku Community",
  Post_date: "Post date",
  Approve: "Approve",
  Reject: "Reject",
  Are_you_sure_to_approved_this_post: "Are you sure to approved this post",
  Are_you_sure_to_rejected_this_post: "Are you sure to rejected this post",
  Cancel: "Cancel",
  Would_you_like_to_cast_this_vote_anonymously:
    "Would you like to cast this vote anonymously?",
  If_you_vote_anonymously:
    "If you vote anonymously, your nickname will not be displayed.",
  Voting_completed_Would_you_like_to_add_a_comment:
    "Voting completed. Would you like to add a comment?",
  Your_nickname_will_be_displayed_differently:
    "Your nickname will be displayed differently depending on whether you are anonymous or not.",
  Add_comment: "Add comment",
  Please_be_considerate_of_others:
    "Please be considerate of others and be polite in your comments. Posts that use offensive or offensive language may be deleted.",
  Edit_Comment: "Edit Comment",
  Using: "Using.",
  Edit_Post: "Edit Post",
  Summary: "[Summary]",
  Vote: "Vote",
  Votes: "Votes",
  Voting_end_date: "Voting end date:",
  View_voting_status: "View voting status",
  I_love_using_this_space_very_comfortable_to_work:
    "I love using this space! The cafe pop music in the background doesn't interfere with my work, making it very comfortable to work. Honestly, I'd be happy if there were vending machines for drinks and snacks.",
  I_would_like_to_use_it_if_the_price:
    "I would like to use it if the price was a little lower.",
  I_use_it_occasionally:
    "I use it occasionally. I'm happy to be able to use it for a short while before picking up my child. However, if you go in the morning, it might be a bit expensive. I would be happy if from now on, if you buy something from Muji on the 1st floor, you will receive a discount or coupon.",
  Add_New_Group: "Add New Group",
  Create_Group: "Create Group",
  Users: "Users",
  Groups: "Groups",
  PendingPosts: "PendingPosts",
  Dashboard_Content: "Dashboard Content",
  Create_New_User: "Create New User",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Email: "Email",
  Password: "Password",
  Confirm_Password: "Confirm Password",
  Create: "Create",
  Edit_Group_Name: "Edit Group Name",
  Save: "Save",
  Group_Name: "Group Name",
  Pending_Posts: "Pending Posts",
  Next: "Next",
  login: "Login",
  UserName: "UserName",
  post_created: "post created",
  go_back: "Back",
  create_group: "Create Group",
  Are_You_Sure: "Are You Sure?",
  Do_you_really_want_to_APPROVE_this_post: "Do you really want to APPROVE this post? This process cannot be undone",
  Do_you_really_want_to_REJECT_this_post: "Do you really want to REJECT this post? This process cannot be undone",
  Your_post_created: "Your post has been created",
  we_found_negative_words:"We found some negative words in your post. It will be published after review",
  Your_vote_added: "Your vote added...!",
  Your_comment_added: "Your comment added...!",
  Pool:"Pool",
  Suggestions:"Suggestions",
  Refresh:"Refresh",
  This_comment_is_not_publicly_visible: "The comment is not posted",
  In_Session: "In Session",
  End_of_page: "End of page",
  Author: "Author",
  Session_timeout_Please_log_in_again:"Session timeout. Please log-in again",
};
