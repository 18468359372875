import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  TextField,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import Paper from "@mui/material/Paper";
import { AsyncImage } from "loadable-image";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../common/colors";
import {
  faClose,
  faThumbsUp as thumbup,
} from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { colors as farmColor } from "../common/colors";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";
import { styles } from "./styles";
import appServices from "../services/app-services/appServices";
import { T } from "react-translator-component";
import deleteicon from "../assets/images/deleteicon.png";
import checkicon from "../assets/images/checkicon.png";
import cancel from "../assets/images/cancel.png";

// import ReactHtmlParser from "react-html-parser";
import "../App.css";
export default function PostView() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [likeUp, setLikeUp] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewStatus, setViewStatus] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState(false);
  const [title, setTitle] = React.useState<any>();
  const [highlightedWords, setHighlightedWords] = React.useState<any>();
  const [commentArea, setCommentArea] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [voteSection, setVoteSection] = React.useState(true);
  //   const [reject, setReject] = React.useState(false);
  const [approve, setApprove] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  // const [negativeTitle, setNegativeTitle] = React.useState(false);
  // const [negativeDetail, setNegativeDetail] = React.useState(false);
  // const [selectedIndex, setSelectedIndex] = React.useState(0);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colors.farm_green500,
    },
  }));

  const handleClickOpen = () => {
    setOpen(false);
  };

  const handlePost = () => {
    setCommentArea(false);
    console.log("handlePost");
  };
  //   useEffect(()=>{
  //     if(state.posts_negative_words.details === "true"){
  //         setNegativeTitle(false)
  //         console.log('setNegativeTitle',negativeTitle)
  //     }
  //     if(state.posts_negative_words.title === "true"){
  //         setNegativeDetail(false)
  //         console.log('setNegativeDetail',negativeDetail)
  //     }
  //   },[])

  const handleVoteAnonymouslyNo = () => {
    setOpen(false);
    setComment(true);
  };
  const handleVoteAnonymouslyYes = () => {
    setOpen(false);
    setComment(true);
  };

  const handleCommentButtons = (yesNo: boolean) => {
    setComment(false);
    setCommentArea(yesNo);
  };
  const focusCommentArea = (input: any) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 500);
    }
  };

  const handleClose = () => {
    setDialog(false);
  };
  const handleBack = () => {
    navigate("/adminhome", { state: { index: "PendingPosts" } });
    console.log("indexxxx");
  };
  const handleOpenReject = () => {
    setDialog(true);
    setApprove(false);
  };
  const handleOpenApprove = () => {
    setDialog(true);
    setApprove(true);
  };
  const handleRejected = async (post_id: number, status: any) => {
    try {
      setDialog(true);
      setApprove(false);
      const response = await appServices.acceptRejectPost(post_id, status);
      console.log(response, "responseeeeeee");
      setDialog(false);
      setTimeout(() => {
        navigate("/adminhome", { state: { someProp: "someValue" } });
      }, 2000);
    } catch (error) {}
  };
  const handleApproved = async (post_id: number, status: any) => {
    try {
      setDialog(true);
      setApprove(true);
      const response = await appServices.acceptRejectPost(post_id, status);
      console.log(response, "responseeeeeee");
      setDialog(false);
      setTimeout(() => {
        navigate("/adminhome");
      }, 3000);
    } catch (error) {}
  };

  console.log("state", state);

  const highlight = (text: any, negativeWords: any) => {
    let highlighText = "";
    const textArray = text.trim().split(" ");
    textArray.forEach((word: string) => {
      negativeWords.forEach((negative: string) => {
        if (word === negative) {
          highlighText += ` <span className="grey-bubble" style="color:red" >${word}</span>`;
        } else {
          highlighText += ` ${word}`;
          // console.log(highlighText);
        }
        if (negativeWords.length === 0) {
          highlighText += ` ${word}`;
        }
      });
    });

    return highlighText;
  };

  useEffect(() => {
    if (state.posts_negative_words.details.has_negative_words) {
      const desc = highlight(
        state.details,
        state.posts_negative_words.details.matched_words
      );
      setHighlightedWords(desc);
    } else {
      setHighlightedWords(state.details);
    }

    if (state.posts_negative_words.title.has_negative_words) {
      const title = highlight(
        state.title,
        state.posts_negative_words.title.matched_words
      );
      setTitle(title);
    } else {
      setTitle(state.title);
    }
  }, [state]);

  return (
    <React.Fragment>
      <div style={styles.detailContainer}>
        <Breadcrumbs aria-label="breadcrumb">
          <ListItemButton onClick={() => navigate(-1)}>
            <Typography color="text.primary">
              {T("Mebuku_Community")}
            </Typography>
          </ListItemButton>

          <Typography color="text.primary">{T("Details")}</Typography>
        </Breadcrumbs>
        <Carousel
          swipeable
          showThumbs={false}
          // selectedItem={slide}
          // onChange={(index) => setSlide(index)}
        >
          {state?.images.length === 0 ? (
            <AsyncImage
              style={{ ...styles.detailCarouselImg, objectFit: "contain" }}
              src={require("../assets/images/default.png")}
              alt=""
            />
          ) : (
            state?.images.map((item: any) => (
              <div key={item.id} style={styles.detailCarousel}>
                <AsyncImage
                  style={styles.detailCarouselImg}
                  src={process.env.REACT_APP_AWS_PATH_BUCKET + item.path}
                  alt=""
                />
              </div>
            ))
          )}
        </Carousel>
        <span
          className="truncate"
          style={{
            ...styles.detailStateTxt,
          }}
        >
          <div
            className="truncate"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={styles.detailPostDate}>
            {T("Post_date")}: 2023/02/14
          </span>
        </div>

        <ListItem
          className=""
          alignItems="flex-start"
          style={{
            ...styles.detailListItem,
            borderBottomStyle: "solid",
            borderTopStyle: "solid",
            paddingLeft: 0,
          }}
        >
          <ListItemAvatar>
            <Avatar
              alt="Remy Sharp"
              src={require("../assets/images/profile.png")}
            />
          </ListItemAvatar>
          <ListItemText
            primary={T("Author")}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", color: "#4792E0" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {state?.user?.nickname}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <span style={styles.detailSummaryTxt}>[Summary]</span>
        <p
          style={{
            fontSize: 16,
            fontFamily: "mplus-1c-regular",
            paddingLeft: 5,
            paddingTop: 5,
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: highlightedWords }} />
        </p>

        <Divider />
        {voteSection ? (
          <div>
            <div style={styles.detailVoteContainer}>
              <p style={styles.detailVoteNormalTxt}>Votes</p>
              <p style={styles.detailPostDate}>
                Voting end date:
                {state.expiry_date}
              </p>
            </div>
            <List
              sx={{
                // selected and (selected + hover) states

                // hover states
                "& .MuiListItemButton-root:hover": {
                  bgcolor: colors.farm_gray100,
                  "&, & .MuiListItemIcon-root": {
                    color: "black",
                  },
                  "&$focusVisible": {
                    backgroundColor: colors.farm_green100,
                  },
                  "& .MuiListItemButton-root:touch": {
                    bgcolor: colors.farm_green100,
                    "&, & .MuiListItemIcon-root": {
                      color: "black",
                    },
                  },
                },
              }}
            >
              {state?.vote_options.map((item: any) => (
                <ListItemButton
                  onClick={() => handleClickOpen()}
                  selected
                  sx={{
                    backgroundColor: colors.farm_green100,
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    margin: 1,
                    fontFamily: "mplus-1c-bold",
                  }}
                  component="p"
                >
                  <ListItemText
                    primary={
                      <span style={styles.communiTitleDescrip}>
                        {item.option_text}
                      </span>
                    }
                    secondary={
                      viewStatus ? (
                        <div style={styles.detailVoteStatusTxt}>
                          <BorderLinearProgress
                            style={{ width: "90%" }}
                            variant="determinate"
                            value={item.votes}
                          />
                          {item.votes}%
                        </div>
                      ) : null
                    }
                  />
                </ListItemButton>
              ))}
            </List>
            <div style={{ ...styles.detailStatusContainer, marginBottom: 20 }}>
              <Dialog open={dialog} onClose={() => handleClose()}>
                <img
                  src={cancel}
                  alt="BigCo Inc. logo"
                  onClick={() => handleClose()}
                  style={{
                    width: 30,
                    height: 30,
                    alignSelf: "flex-end",
                    marginRight: 10,
                    marginTop: 10,
                  }}
                />
                {approve ? (
                  <img
                    src={checkicon}
                    alt="BigCo Inc. logo"
                    style={{
                      width: 100,
                      height: 100,
                      alignSelf: "center",
                      color: colors.farm_red600,
                    }}
                  />
                ) : (
                  <img
                    src={deleteicon}
                    alt="BigCo Inc. logo"
                    style={{
                      width: 100,
                      height: 100,
                      alignSelf: "center",
                      color: colors.farm_red600,
                    }}
                  />
                )}
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    marginTop: 20,
                    marginBottom: 20,
                    height: 20,
                  }}
                >
                  Are You Sure?
                </Typography>
                <DialogTitle
                  style={{
                    ...styles.adminUserDialogTitle,
                    color: approve ? colors.farm_green500 : colors.farm_red600,
                    textAlign: "center",
                  }}
                >
                  {approve
                    ? T(
                        "Do_you_really_want_to_APPROVE_this_post"
                      )
                    : T(
                        "Do_you_really_want_to_REJECT_this_post"
                      )}
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{ ...styles.adminUserDialogContent, marginTop: 0 }}
                  >
                    <Button
                      onClick={() => handleClose()}
                      variant="contained"
                      style={{
                        textAlign: "center",
                        backgroundColor: "gray",
                        marginRight: 5,
                      }}
                    >
                      {T("Cancel")}
                    </Button>
                    {approve ? (
                      <Button
                        onClick={() => handleApproved(state.id, "approve")}
                        variant="contained"
                        color={"success"}
                        sx={{ textAlign: "center", alignItems: "center" }}
                      >
                        {T("Approve")}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleRejected(state.id, "reject")}
                        variant="contained"
                        color={"error"}
                        sx={{ textAlign: "center" }}
                      >
                        {T("Reject")}
                      </Button>
                    )}
                  </div>
                </DialogContent>
              </Dialog>
              {/* <Dialog open={dialog} onClose={() => handleClose()}>
                <img
                  src={cancel}
                  alt="BigCo Inc. logo"
                  onClick={() => handleClose()}
                  style={{
                    width: 30,
                    height: 30,
                    alignSelf: "flex-end",
                    marginRight: 10,
                    marginTop: 10,
                  }}
                />
                {approve ? (
                  <img
                    src={checkicon}
                    alt="BigCo Inc. logo"
                    style={{
                      width: 100,
                      height: 100,
                      alignSelf: "center",
                      color: colors.farm_red600,
                    }}
                  />
                ) : (
                  <img
                    src={deleteicon}
                    alt="BigCo Inc. logo"
                    style={{
                      width: 100,
                      height: 100,
                      alignSelf: "center",
                      color: colors.farm_red600,
                    }}
                  />
                )}
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    marginTop: 20,
                    marginBottom: 20,
                    height: 20,
                  }}
                >
                  Are You Sure?
                </Typography>
                <DialogTitle
                  style={{
                    ...styles.adminUserDialogTitle,
                    color: approve ? colors.farm_green500 : colors.farm_red600,
                    textAlign: "center",
                  }}
                >
                  {approve
                    ? T(
                        "Do you really want to APPROVE this post? This process cannot be undone"
                      )
                    : T(
                        "Do you really want to REJECT this post? This process cannot be undone"
                      )}
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{ ...styles.adminUserDialogContent, marginTop: 0 }}
                  >
                    <Button
                      onClick={() => handleClose()}
                      variant="contained"
                      style={{
                        textAlign: "center",
                        backgroundColor: "gray",
                        marginRight: 5,
                      }}
                    >
                      {T("Cancel")}
                    </Button>
                    {approve ? (
                      <Button
                        onClick={() => handleApproved(state.id, "approve")}
                        variant="contained"
                        color={"success"}
                        sx={{ textAlign: "center", alignItems: "center" }}
                      >
                        {T("Approve")}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleRejected(state.id, "reject")}
                        variant="contained"
                        color={"error"}
                        sx={{ textAlign: "center" }}
                      >
                        {T("Reject")}
                      </Button>
                    )}
                  </div>
                </DialogContent>
              </Dialog> */}
              <Button
                // onClick={() => handleRejected(state.id, "reject")}
                onClick={() => handleBack()}
                variant="contained"
                sx={{ marginRight: 3, backgroundColor: "gray" }}
              >
                {T("go_back")}
              </Button>
              <Button
                // onClick={() => handleRejected(state.id, "reject")}
                onClick={() => handleOpenReject()}
                variant="contained"
                color="error"
                sx={{ marginRight: 3 }}
              >
                {T("Reject")}
              </Button>
              <Button
                onClick={() => handleOpenApprove()}
                // onClick={() => handleApproved(state.id, "accept")}
                variant="contained"
                color={"success"}
              >
                {T("Approve")}
              </Button>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {T("Would_you_like_to_cast_this_vote_anonymously")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {T("If_you_vote_anonymously")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button color={"secondary"} onClick={handleVoteAnonymouslyNo}>
                    {T("No")}
                  </Button>
                  <Button
                    sx={{ fontFamily: "mplus-1c-bold" }}
                    color={"secondary"}
                    onClick={handleVoteAnonymouslyYes}
                    autoFocus
                  >
                    {T("Yes")}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={comment}
                onClose={() => setComment(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {T("Voting_completed_Would_you_like_to_add_a_comment")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {T("Your_nickname_will_be_displayed_differently")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color={"secondary"}
                    onClick={() => handleCommentButtons(false)}
                  >
                    {T("No")}
                  </Button>
                  <Button
                    sx={{ fontFamily: "mplus-1c-bold" }}
                    color={"secondary"}
                    onClick={() => handleCommentButtons(true)}
                    autoFocus
                  >
                    {T("Yes")}
                  </Button>
                </DialogActions>
              </Dialog>
              <Backdrop
                sx={{
                  color: "#fff",
                  touchAction: "none",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={commentArea}
                onClick={() => console.log("ok")}
              >
                <TrapFocus open disableEnforceFocus>
                  <Fade appear={false} in={commentArea}>
                    <Paper
                      role="dialog"
                      aria-modal="true"
                      aria-label="banner"
                      square
                      variant="outlined"
                      tabIndex={-1}
                      sx={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        m: 0,
                        p: 2,
                        borderWidth: 0,
                        borderTopWidth: 1,
                      }}
                    >
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent="space-between"
                        gap={2}
                      >
                        <Box
                          sx={{
                            flexShrink: 1,
                          }}
                        >
                          <Stack
                            gap={4}
                            sx={{
                              flexShrink: 1,

                              alignItems: "center",
                            }}
                            direction={{
                              xs: "row",
                              sm: "row",
                            }}
                          >
                            <IconButton
                              onClick={() => setCommentArea(false)}
                              sx={{ p: "10px" }}
                              aria-label="menu"
                            >
                              <FontAwesomeIcon
                                size={"lg"}
                                icon={faClose}
                                color={farmColor.farm_gray200}
                              />
                            </IconButton>
                            <Typography fontWeight="bold">
                              {T("Add_comment")}
                            </Typography>
                          </Stack>

                          <Typography variant="body2">
                            {T("Please_be_considerate_of_others")}
                          </Typography>
                          {commentArea && (
                            <TextField
                              autoFocus
                              focused={true}
                              ref={focusCommentArea}
                              fullWidth
                              id="filled-multiline-static"
                              hiddenLabel
                              multiline
                              rows={4}
                              variant="filled"
                            />
                          )}
                        </Box>
                        <Stack
                          gap={4}
                          direction={{
                            xs: "row-reverse",
                            sm: "row",
                          }}
                          sx={{
                            flexShrink: 0,
                            alignSelf: { xs: "flex-end", sm: "center" },
                          }}
                        >
                          <Button
                            size="small"
                            onClick={() => setCommentArea(false)}
                            variant="contained"
                            color="secondary"
                          >
                            {T("Post")}
                          </Button>
                        </Stack>
                      </Stack>
                    </Paper>
                  </Fade>
                </TrapFocus>
              </Backdrop>
            </div>
          </div>
        ) : (
          <div style={{ minHeight: 200 }}>
            <p style={styles.detailVoteNormalTxt}>{T("Comments")}</p>
            <Box sx={styles.detailCommentBox}>
              <div style={styles.detailVoteContainer}>
                <p style={styles.detailPostDate}>Vote</p>
                <Button
                  onClick={() => setCommentArea(true)}
                  style={styles.detailPostDate}
                  variant="text"
                >
                  {T("Edit_Comment")}
                </Button>
              </div>
              <p
                style={{ fontFamily: "mplus-1c-bold", fontSize: 16, margin: 0 }}
              >
                Using
              </p>
              <Divider />
              <p style={styles.detailPostDate}>{T("Comments")}</p>
              <Typography fontSize={16}>
                I love using this space! The cafe pop music in the background
                doesn't interfere with my work, making it very comfortable to
                work. Honestly, I'd be happy if there were vending machines for
                drinks and snacks.
              </Typography>
              <Stack
                direction={{
                  xs: "row-reverse",
                  sm: "row",
                }}
              >
                <div>
                  <IconButton
                    onClick={() => setLikeUp(!likeUp)}
                    aria-label="thumbs"
                  >
                    <FontAwesomeIcon
                      size={"lg"}
                      icon={likeUp ? thumbup : faThumbsUp}
                      color={
                        likeUp
                          ? farmColor.farm_green500
                          : farmColor.farm_gray200
                      }
                    />
                  </IconButton>
                  <span style={styles.detailStatLike}>{20}</span>
                </div>
              </Stack>
            </Box>
            <Box
              sx={{
                flexShrink: 1,
                backgroundColor: colors.farm_gray10,
                borderRadius: 4,
                padding: 2,
                marginTop: 1,
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p
                  style={{
                    fontFamily: "mplus-1c-regular",
                    fontSize: 12,
                    color: colors.farm_gray200,
                  }}
                >
                  {T("Vote")}
                </p>
              </div>
              <p style={styles.detailCommentNormalTxt}>
                I would like to use it if the price was a little lower.
              </p>
              <Divider />
              <p style={styles.detailPostDate}>{T("Comments")}</p>
              <Typography fontSize={16}>
                I use it occasionally. I'm happy to be able to use it for a
                short while before picking up my child. However, if you go in
                the morning, it might be a bit expensive. I would be happy if
                from now on, if you buy something from Muji on the 1st floor,
                you will receive a discount or coupon.
              </Typography>
              <Stack
                direction={{
                  xs: "row-reverse",
                  sm: "row",
                }}
              >
                <div>
                  <IconButton aria-label="thumbs">
                    <FontAwesomeIcon
                      onClick={() => setLikeUp(!likeUp)}
                      size={"lg"}
                      icon={likeUp ? thumbup : faThumbsUp}
                      color={
                        likeUp
                          ? farmColor.farm_green500
                          : farmColor.farm_gray200
                      }
                    />
                  </IconButton>
                  <span style={styles.detailStatLike}>{20}</span>
                </div>
              </Stack>
            </Box>
            <Backdrop
              sx={{
                color: "#fff",
                touchAction: "none",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={commentArea}
              onClick={() => console.log("ok")}
            >
              <TrapFocus open disableEnforceFocus>
                <Fade appear={false} in={commentArea}>
                  <Paper
                    role="dialog"
                    aria-modal="true"
                    aria-label="banner"
                    square
                    variant="outlined"
                    tabIndex={-1}
                    sx={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      m: 0,
                      p: 2,
                      borderWidth: 0,
                      borderTopWidth: 1,
                    }}
                  >
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Box
                        sx={{
                          flexShrink: 1,
                        }}
                      >
                        <Stack
                          gap={4}
                          sx={{
                            flexShrink: 1,

                            alignItems: "center",
                          }}
                          direction={{
                            xs: "row",
                            sm: "row",
                          }}
                        >
                          <IconButton sx={{ p: "10px" }} aria-label="menu">
                            <FontAwesomeIcon
                              onClick={() => setCommentArea(false)}
                              size={"lg"}
                              icon={faClose}
                              color={farmColor.farm_gray200}
                            />
                          </IconButton>
                          <Typography fontWeight="bold">
                            {T("Add_comment")}
                          </Typography>
                        </Stack>

                        <Typography variant="body2">
                          {T("Please_be_considerate_of_others")}
                        </Typography>
                        {commentArea && (
                          <TextField
                            autoFocus
                            focused={true}
                            ref={focusCommentArea}
                            fullWidth
                            id="filled-multiline-static"
                            hiddenLabel
                            multiline
                            minRows={4}
                            maxRows={6}
                            defaultValue={T(
                              "I_love_using_this_space_very_comfortable_to_work"
                            )}
                            variant="filled"
                          />
                        )}
                      </Box>
                      <Stack
                        gap={4}
                        direction={{
                          xs: "row-reverse",
                          sm: "row",
                        }}
                        sx={{
                          flexShrink: 0,
                          alignSelf: { xs: "flex-end", sm: "center" },
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => handlePost()}
                          variant="contained"
                          color="secondary"
                        >
                          {T("Post")}
                        </Button>
                      </Stack>
                    </Stack>
                  </Paper>
                </Fade>
              </TrapFocus>
            </Backdrop>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
