import { List } from "@mui/material";
import SeeMoreLarge from "../SeeMoreLarge";
import SeeMoreSmall from "../SeeMoreSmall";
interface Props {
  items: any;
  onClickItem({ item }: any): void;
}

export default function SeeMoreList({ items, onClickItem }: Props) {
  return (
    <List>
      {items.map((item: any, index: number) =>
        index === 0 ? (
          <SeeMoreLarge onClickItem={onClickItem} item={item} index={index} />
        ) : (
          <SeeMoreSmall onClickItem={onClickItem} item={item} index={index} />
        )
      )}
    </List>
  );
}
