// import axiosService from "../axios-util/axios-services";

import axiosService from "../axios-util/axios-services";

export type LoginRequestBody = { email: string; password: string };
export type UserBody = {name: string; nickname: string;
email: string;
role: string;
id_kind: string;
version: string;
client_id:string;
image: string;
mebuku_id: string; };

export type User = {
	user_id: any;
	key: string;
	email: string;
	// profile: {
	// 	user_id: number;
	// 	username: string;
	// 	first_name: string;
	// 	last_name: string;
	// 	avatar_url: string;
	// };
}
const login = async (body: LoginRequestBody): Promise<User> => {
	console.log('we are calling API from here now, body', body);
	// const response = await axiosService.post<User>(`login/`, {
	// 	...body
	// });
	const  data  ={email:'',user_id:1,profile:{},key:'fU5DZc1HCdlBzfJZ5k9ECR7tU8a11685OWZ0J9T1QqWgqWvdbR538aOjGfP4SMpG'};
return data
}

const registerAppUser = async (user: UserBody): Promise<any> => {
	console.log('we are calling API from here now, body', user);
	let body = user
	console.log(JSON.stringify(body))
	const response = await axiosService.post(`external/users/register`, body);	
	
	return response
}
const registerAdminUser = async (user: UserBody): Promise<any> => {
	console.log('we are calling API from here now, body', user);
	let body =user
	console.log(JSON.stringify(body))
	const response = await axiosService.post(`users`, body);	
	
	return response
}

const loginWithToken = async (token: string): Promise<any> => {
	console.log('here login api')
	try {
		const response = await axiosService.get(`external/users/find_by_token`);
		console.log(response)
		return response
	} catch (error) {
		console.log(error)
		return error
	}
	
}



const loginAdmin = async (body: LoginRequestBody): Promise<any> => {
	// console.log('we are calling API from here now, body', body);
	const response = await axiosService.post(`users/login`,body);
	 console.log('r',response)
	return response	
}

const updateAppUser = async (user_id:any,body: any) => {
	// console.log('we are calling API from here now, body', body);
	const response = await axiosService.put(`users/${user_id}`,body);
	 console.log('r',response)
	return response	
}


const loginUser = async (): Promise<User> => {
	// console.log('we are calling API from here now, body', body);
	// const response = await axiosService.post<User>(`login/`, {
	// 	...body
	// });
	const  data  ={email:'',user_id:1,profile:{},key:'',user_name:'Shahid Ali'};
return data
}
const authService = {
	login,
	loginUser,
	loginAdmin,
	registerAppUser,
	loginWithToken,
	registerAdminUser,
	updateAppUser
};

export default authService;