import React, { useEffect, useState } from "react";
import DropDown from "../components/DropDown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// import { faSave } from "@fortawesome/free-regular-svg-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors } from "../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import appServices from "../services/app-services/appServices";
import LoadingModal from "../components/LoadingModal";
import { useLocation, useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import uploadFiles from "../util/upload-multiple-file";
import { AsyncImage } from "loadable-image";
import { T } from "react-translator-component";
import { useSelector } from "react-redux";
import { styles } from "./styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const MAX_LENGTH = 2500;
const Max_TITLE_LENGTH = 20;
const MAX_VOTE_LENGTH = 100;

export default function CreatePost() {
  const editPost = useLocation().state;
  const { user } = useSelector((state: any) => state.userAuth);
  const navigate = useNavigate();
  const [communityError, setCommunityError] = useState(false);
  const [groupsError, setGroupsError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descError, setDescError] = useState(false);
  // const [imageError, setImageError] = useState(false);
  const [vote1Error, setVote1Error] = useState(false);
  const [vote2Error, setVote2Error] = useState(false);
  // const [vote3Error, setVote3Error] = useState(false);
  // const [vote4Error, setVote4Error] = useState(false);
  // const [participantsError, setParticipantsError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const [images, setImage] = useState<ImageItem[]>([]);
  const [removedImages, setRemovedImage] = useState<ImageItem[]>([]);
  const [fileToUpload, setFilesToUpload] = useState<ImageItem[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<ImageItem[]>([]);
  const [accept, setAccept] = useState("Yes");
  const [title, setTitle] = useState<any>("");
  const [desc, setDesc] = useState("");
  const [participants, setParticipants] = useState("No");
  const [noOfParticipants, setNoOfParticipants] = useState("");
  const [anonymous, setAnonymous] = useState("1");
  const [postPeriod, setPostPeriod] = useState("90-days");
  const [groupsOptions, setGroupsOptions] = useState<any>([]);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [snake, setSnake] = useState("");
  const [postEndDate, setPostEndDate] = useState<Dayjs>(
    dayjs(moment().add(20, "days").calendar())
  );
  const [agree, setAgree] = useState(false);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [editTags, setEditTags] = useState<any[]>([]);
  const [removedTags, setRemovedTags] = useState<any[]>([]);
  const [finalEdit, setFinalEditTags] = useState<any[]>([]);
  const [votesCount, setVoteCounts] = useState(0);
  const [acceptVotes, setAcceptVotes] = useState("No");
  const [disabled, setDisabled] = useState(false);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const [votingContent, setVotingContent] = useState<any>([
    { id: undefined, post_id: undefined, inputText: "" },
    { id: undefined, post_id: undefined, inputText: "" },
    // { id: undefined, post_id: undefined, inputText: "" },
  ]);
  const [category, setSelectedCategory] = useState<any>({
    value: "",
    name: "Category",
  });
  const [group, setSelectedGroup] = useState<any>({
    value: "",
    name: "Group",
  });

  const handleSelectGroup = (event: SelectChangeEvent) => {
    const v = {
      value: event.target.value,
      name: event.target.value === "" ? "Category" : event.target.name,
    };
    setSelectedGroup(v);
    setGroupsError(false);
  };
  const handleSelect = (event: SelectChangeEvent) => {
    const v = {
      value: event.target.value,
      name: event.target.value === "" ? "Category" : event.target.name,
    };
    setSelectedCategory(v);
    setCommunityError(false);
  };

  useEffect(() => {
    apis();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const filesToUpload = images.filter((img) => img.file !== undefined);
    setFilesToUpload(filesToUpload);

    const filesUploaded = images.filter((img) => img.path !== undefined);
    setFilesUploaded(filesUploaded);
  }, [images]);

  const apis = async () => {
    setVisible(true);
    try {
      setVisible(true);
      const groups = await appServices.getGroups(1);
      setVisible(false);
      setGroupsOptions(groups);
    } catch (error) {
      setVisible(false);
    }
    try {
      setVisible(true);
      const categories = await appServices.getCategory();
      setVisible(false);

      setCategoryOptions(categories);
    } catch (error) {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (editPost) {
      const post = editPost.post;
      setTitle(post.title);
      setDesc(post.details);
      setAccept(post.enable_comments ? "Yes" : "No");
      setParticipants(post.enable_participants ? "Yes" : "No");
      setSelectedGroup({ value: post.group?.id, name: post.group?.name });
      setSelectedCategory({
        value: post.category.id,
        name: post.category.name,
      });
      setAnonymous(post.is_anonymous);
      setNoOfParticipants(post.number_of_participants);
      setPostEndDate(dayjs(post.expiry_date));
      const tags = post.post_tags.map((tag: any) => tag.tag);
      setSelectedTags(tags);
      setEditTags(post.post_tags);

      const votes = post.vote_options.map((vote: any) => {
        return {
          id: vote.id,
          post_id: vote.post_id,
          inputText: vote.option_text,
        };
      });
      // console.log(votes);

      const imgs = post.images.map((img: any) => {
        return {
          path: img.path,
          id: img.id,
          name: img.path,
          file: undefined,
          imageSrc: undefined,
        };
      });
      setImage(imgs);
      if (votes.length !== 0) {
        setAcceptVotes("Yes");
        setVotingContent(votes);
      }

      // const count = post?.vote_options.reduce((a: any, b: any) => {
      //   return Number(a.votes_count) + Number(b.vote_count);
      // }, 0);

      let total = post?.vote_options.reduce(
        (a: any, { votes_count, option_text }: any) => {
          a = a + votes_count;
          return a;
        },
        0
      );
      // console.log("count", total);

      setVoteCounts(total);
    }
  }, [editPost]);

  useEffect(() => {
    const select = selectedTags.map((tag) => {
      return { tag: tag };
    });
    // console.log(select);
    const all = select.concat(editTags);
    // var ages = array.map(function(obj) { return obj.age; });
    const uniqueArray = makeArrayUnique(all);
    // console.log(uniqueArray);

    const f = uniqueArray.concat(removedTags);
    const fin = makeArrayUnique(f);
    // console.log("fin", fin);
    setFinalEditTags(fin);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags, removedTags]);
  function makeArrayUnique(inputArray: any) {
    const uniqueMap = new Map();

    // Iterate through the array and use the "tag" property as the key in the map
    inputArray.forEach((item: any) => {
      if (item.tag) {
        uniqueMap.set(item.tag.toLowerCase(), item);
      }
    });

    // Convert the values of the map back to an array
    const uniqueArray = Array.from(uniqueMap.values());

    return uniqueArray;
  }

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        const img = [
          ...images,
          {
            name: event.target.files[0].name,
            file: event.target.files[0],
            imageSrc: e.target.result,
            path: undefined,
            _destroy: "0",
          },
        ];
        setImage(img);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const deleteItem = React.useCallback((itemToDelete: ImageItem) => {
    setImage((currentItems) =>
      currentItems.filter((item) => item.name !== itemToDelete.name)
    );
  }, []);

  const saveRemoveImg = (img: ImageItem) => {
    if (img.path !== undefined) {
      const item = img;
      const addDestroy = [...removedImages, { ...item, _destroy: "1" }];
      // console.log(addDestroy);
      setRemovedImage(addDestroy);
      // console.log(removedImages);
    }
  };

  const handleDeleteTag = (tag: string) => {
    if (editPost) {
      try {
        const post = editPost.post;
        const removedTg = post.post_tags.filter(
          (item: any) => item.tag === tag
        );
        // console.log("removedTg", removedTg);
        const removed = [
          ...removedTags,
          { tag: tag, _destroy: "1", id: removedTg[0].id },
        ];
        setRemovedTags(removed);
      } catch (error) {}
    }
  };

  const addValue = (value: string, index: number) => {
    setVotingContent((prevState: any) => {
      return prevState.map((prevValue: any, i: number) => {
        return {
          inputText: index === i ? value : prevValue.inputText,
          id: prevValue?.id,
          post_id: prevValue?.post_id,
        };
      });
    });
  };

  const addContentField = () => {
    setVotingContent([...votingContent, { inputText: "" }]);
  };

  const delVote = (index: number) => {
    // Create a copy of votingContent
    const updatedVotingContent = [...votingContent];
    // Remove the element at the specified index
    updatedVotingContent.splice(index, 1);
    // Update the state with the modified array
    setVotingContent(updatedVotingContent);
  };

  const handleAccept = (value: any) => {
    setAccept(value.target.value);
    if (agree) {
      setAgreeError(false);
    }
  };

  const handleAcceptVotes = (value: any) => {
    // console.log(value.target.value);
    setAcceptVotes(value.target.value);
  };

  const handleDate = (newValue: any) => {
    console.log(newValue);
    setPostEndDate(newValue || undefined);
    setTimeout(() => {
      if (postEndDate !== undefined) {
        setDateError(false);
      }
    }, 100);
  };
  const handlePostPeriod = (value: any) => {
    setPostPeriod(value.target.value);
  };
  const handleAnonymous = (value: any) => {
    setAnonymous(value.target.value);
  };

  //////Errors

  const showIfErrors = async () => {
    if (category.value === "") {
      setCommunityError(true);
    } else {
      setCommunityError(false);
    }
    if (group.value === "") {
      setGroupsError(true);
    } else {
      setGroupsError(false);
    }
    if (title?.trim()?.length <= 1) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }
    if (desc.trim() === "") {
      setDescError(true);
    } else {
      setDescError(false);
    }

    if (votingContent[0].inputText?.trim() === "" && acceptVotes === "Yes") {
      setVote1Error(true);
    } else {
      setVote1Error(false);
    }
    if (votingContent[1].inputText?.trim() === "" && acceptVotes === "Yes") {
      setVote2Error(true);
    } else {
      setVote2Error(false);
    }

    if (postPeriod !== "90-days" && postEndDate === undefined) {
      setDateError(true);
    } else {
      setDateError(false);
    }
    if (!agree) {
      setAgreeError(true);
    } else {
      setAgreeError(false);
    }
  };

  const post = async () => {
    showIfErrors().then(async (res) => {
      if (
        category.value !== "" &&
        group.value !== "" &&
        title?.trim()?.length !== 0 &&
        desc?.trim() !== "" &&
        agree
      ) {
        if (
          acceptVotes === "No" ||
          (votingContent[0].inputText?.trim() !== "" &&
            votingContent[1].inputText?.trim() !== "")
        ) {
          if (participants === "Yes" && noOfParticipants === "") {
            // console.log("nopost participants");
          } else {
            if (postPeriod !== "90-days" && postEndDate === undefined) {
              // console.log("nopost postEndDate");
            } else {
              try {
                setVisible(true);
                const tags = selectedTags.map((tag) => {
                  return { tag: tag };
                });
                if (editPost) {
                  let allImages: any;
                  if (fileToUpload) {
                    console.log(fileToUpload);
                    allImages = await uploadFiles.uploadMultipleImages(
                      fileToUpload
                    );
                  }
                  const votesWithTextOnly = votingContent.filter(
                    (item: { inputText: string }) => item.inputText !== ""
                  );
                  const votes = votesWithTextOnly.map(
                    (item: { inputText: any }) => {
                      return { option_text: item.inputText, ...item };
                    }
                  );

                  let body = {
                    post: {
                      title: title,
                      details: desc,
                      user_id: 1,
                      group_id: group.value,
                      category_id: category.value,
                      moderation_count: 0,
                      expiry_date: votes?.length
                        ? moment(postEndDate.toISOString()).add(1, "days")
                        : moment().subtract(2, "days"),
                      is_anonymous: anonymous,
                      enable_comments: accept === "Yes" ? true : false,
                      enable_participants:
                        participants === "Yes" ? true : false,
                      number_of_participants: noOfParticipants,
                      images_attributes: [
                        ...allImages,
                        ...removedImages,
                        ...filesUploaded,
                      ],
                      tags_attributes: finalEdit,
                      vote_options_attributes: votes,
                      post_type: user?.source === "admin" ? "1" : "0",
                    },
                  };
                  console.log(body);

                  try {
                    setDisabled(true);
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const res = await appServices.editPost(
                      editPost.post.id,
                      body
                    );
                    console.log(res);
                    const desc = `投稿が作成されました。 ${
                      res.data.post.status === "Pending"
                        ? T("we_found_negative_words")
                        : ""
                    }`;
                    setSnake(desc);

                    setTimeout(() => {
                      if (user.source === "admin") {
                        navigate("/facilitator");
                      } else {
                        navigate("/categories");
                      }
                    }, 2000);
                    setVisible(false);
                  } catch (error) {
                    setDisabled(false);
                    setVisible(false);
                  }
                } else {
                  console.log(fileToUpload);
                  const allImages = await uploadFiles.uploadMultipleImages(
                    fileToUpload
                  );
                  const votesWithTextOnly = votingContent.filter(
                    (item: { inputText: string }) => item.inputText !== ""
                  );
                  const votes = votesWithTextOnly.map(
                    (item: { inputText: any }) => {
                      return { option_text: item.inputText };
                    }
                  );

                  let body = {
                    post: {
                      title: title,
                      details: desc,
                      user_id: 1,
                      group_id: group.value,
                      category_id: category.value,
                      moderation_count: 0,
                      expiry_date: votes?.length
                        ? moment(postEndDate.toISOString()).add(1, "days")
                        : moment().subtract(2, "days"),
                      is_anonymous: anonymous,
                      enable_comments: accept === "Yes" ? true : false,
                      enable_participants:
                        participants === "Yes" ? true : false,
                      number_of_participants: noOfParticipants,
                      images_attributes: allImages,
                      tags_attributes: tags,
                      vote_options_attributes: votes,
                      post_type: user?.source === "admin" ? "1" : "0",
                    },
                  };
                  console.log(body);

                  try {
                    setDisabled(true);
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const res = await appServices.savePost(body);
                    console.log(res.data.post);

                    const desc = `Your post created...! ${
                      res.data.post.status === "Pending"
                        ? "\n we found some negative words in your post. It will be published after review."
                        : ""
                    }`;
                    setSnake(desc);
                    setTimeout(() => {
                      if (user?.source === "admin") {
                        navigate("/facilitator");
                      } else {
                        navigate("/categories");
                      }
                    }, 2500);
                    setVisible(false);
                  } catch (error) {
                    setVisible(false);
                    setDisabled(false);
                  }
                }

                setVisible(false);
              } catch (error) {
                setVisible(false);
              }
            }
          }
        } else {
          console.log("here in else votes");
        }
      } else {
        console.log("here in else");
        // setVisible(false);
      }
    });
  };

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };

  const getValue = (index: number) => {
    let temp = votingContent;

    return temp[index].inputText;
  };

  const handleButtonClick = () => {
    const InputElement: any = document.querySelector(`#id .rti--input`) || "";
    if (InputElement) {
      const value = InputElement?.value;
      if (!selectedTags.includes(value) && value !== "") {
        setSelectedTags([...selectedTags, value]);
        InputElement.value = "";
      }
    }
  };
  // console.log("user", user);

  const handleImageClick = () => {
    console.log("button image upload clicked");
    try {
      // const PortalAPP = (window as any).PortalAPP;
      // window.UploadImage();
      window.parent.postMessage("image_upload", "*");
    } catch (error) {
      console.log("image upload", error);
    }
  };

  // useEffect(() => {
  //   // const PortalAPP = window.PortalAPP;
  //   // eslint-disable-next-line
  //   function portalAppUploadImageCallBack(result: any) {
  //     alert(result?.image_data);
  //     if (result.result_code === 200) {
  //       const file = base64ToFileURI(result?.image_data, "image/png");
  //       const img = [
  //         ...images,
  //         {
  //           name: "any.png",
  //           file: file,
  //           imageSrc: file,
  //           path: undefined,
  //           _destroy: "0",
  //         },
  //       ];
  //       setImage(img);
  //     }
  //   }
  //   try {
  //     (window as any).portalAppUploadImageCallBack =
  //       portalAppUploadImageCallBack;
  //   } catch (error) {}

  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    function handleMessage(event: any) {
      // Check if the message is coming from the parent window

      console.log("message received");
      if (event.source === window.parent) {
        // Log the message sent from the parent window

        if (
          event?.data === "" ||
          event?.data === null ||
          event?.data === undefined
        )
          return;
        // alert(event?.data);

        // alert(base64Data);
        try {
          const base64Data = event?.data.split(",")[1];
          const byteCharacters = atob(base64Data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const fileName = `any_${timestamp}.png`;

          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: "image/jpeg" });

          setImage((prevImages) => [
            ...prevImages,
            {
              name: fileName,
              file: file,
              imageSrc: event?.data,
              path: undefined,
              _destroy: "0",
            },
          ]);
        } catch (error) {
          // alert("error" + error);
        }

        // Example: Send a response back to the parent window
        // event.source.postMessage('Hello from iframe!', event.origin);
      }
    }

    // Add an event listener to listen for messages from the parent window
    window.addEventListener("message", handleMessage);

    // eslint-disable-next-line
  }, []);

  // const base64ToFileURI = (base64String: string, mimeType: string) => {
  //   // Decode the Base64 string into binary data
  //   const binaryString = atob(base64String);

  //   // Create a typed array to store the binary data
  //   const length = binaryString.length;
  //   const bytes = new Uint8Array(length);
  //   for (let i = 0; i < length; i++) {
  //     bytes[i] = binaryString.charCodeAt(i);
  //   }

  //   // Create a Blob object from the binary data
  //   const blob = new Blob([bytes.buffer], { type: mimeType });

  //   // Create a URL representing the Blob object
  //   const fileURI = URL.createObjectURL(blob);

  //   return fileURI;
  // };

  return (
    <div style={{ marginTop: 80, overflow: "scroll" }}>
      {/* eslint-disable-next-line eqeqeq */}
      {user.id_kind !== "2" ? (
        <div style={{ justifyContent: "start", overflow: "hidden" }}>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <span
              style={{
                marginTop: 20,
                fontSize: 24,
                fontFamily: "mplus-1c-regular",
                paddingLeft: 6,
                fontWeight: "600",
              }}
            >
              {T("Post_page")}
            </span>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "mplus-1c-regular",
              fontSize: 16,
            }}
          >
            {T("Post_Create_Des")}
          </p>
          <Stack
            component="form"
            sx={
              {
                // width: "100%",
              }
            }
            spacing={2}
            noValidate
            autoComplete="off"
          >
            <Stack
              component="form"
              sx={
                {
                  // width: "100%",
                }
              }
              spacing={2}
              noValidate
              autoComplete="off"
              style={{
                backgroundColor: colors.farm_gray10,
                padding: 10,
                width: "100%",
              }}
            >
              <DropDown
                title={T("Category")}
                value={category.value}
                error={communityError}
                options={categoryOptions}
                star
                onChange={handleSelect}
              />

              <DropDown
                title={T("Group")}
                value={group.value}
                error={groupsError}
                options={groupsOptions}
                star
                onChange={handleSelectGroup}
              />
              <span
                style={{
                  marginBottom: -10,
                  fontFamily: "mplus-1c-bold",
                }}
              >
                {T("Title")}
                <span style={{ color: colors.farm_red600 }}> *&nbsp;</span>
              </span>
              <TextField
                error={titleError}
                value={title}
                helperText={titleError ? "* fields are required. " : ""}
                hiddenLabel
                multiline
                rows={2}
                inputProps={{ maxLength: Max_TITLE_LENGTH }}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (title.length > 0) {
                    setTitleError(false);
                  }
                }}
              />
              <span
                style={{
                  marginTop: -1,
                  fontFamily: "mplus-1c-regular",
                  fontSize: 10,
                  alignSelf: "end",
                  position: "relative",
                }}
              >
                {title.length}/{Max_TITLE_LENGTH}
              </span>
              <span style={{ height: 5 }}></span>
              <span style={{ marginBottom: -10, fontFamily: "mplus-1c-bold" }}>
                {T("Details")}
                <span style={{ color: colors.farm_red600 }}> *&nbsp;</span>
              </span>
              <TextField
                error={descError}
                value={desc}
                helperText={descError ? "* fields are required.." : ""}
                sx={{ justifyContent: "start" }}
                hiddenLabel
                multiline
                rows={7}
                inputProps={{ maxLength: MAX_LENGTH }}
                onChange={(e) => {
                  setDesc(e.target.value);
                  if (desc.length > 0) {
                    setDescError(false);
                  }
                }}
              />
              <span
                style={{
                  marginTop: -1,
                  fontFamily: "mplus-1c-regular",
                  fontSize: 10,
                  alignSelf: "end",
                  position: "relative",
                }}
              >
                {desc.length}/{MAX_LENGTH}
              </span>
              <span style={{ marginBottom: -10, fontFamily: "mplus-1c-bold" }}>
                {T("Tag")}
              </span>
              <div
                id={"id"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ width: "95%" }}>
                  {" "}
                  <TagsInput
                    value={selectedTags}
                    onChange={setSelectedTags}
                    onRemoved={handleDeleteTag}
                    name="fruits"
                    separators={[" ", ",", "Enter", "return"]}
                  />
                </div>

                <IconButton
                  onClick={handleButtonClick}
                  aria-label="add"
                  size="small"
                >
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              </div>
              {/* <TextField hiddenLabel multiline rows={2} /> */}
            </Stack>
            {/*  */}

            {/* android */}

            {(user.client_id === "CL001" || user.client_id === "CL015") && (
              <>
                <Stack sx={{ display: "flex", alignItems: "center" }}>
                  {images.length === 0 && (
                    <Typography
                      // color={imageError ? "red" : "black"}
                      fontFamily={"mplus-1c-bold"}
                      sx={{ textDecoration: "underline", marginBottom: -2 }}
                    >
                      {T("Upload_Images")}
                    </Typography>
                  )}
                </Stack>
                <Carousel responsive={responsive}>
                  {images.map((item) => (
                    <div
                      key={item.name}
                      style={{ height: 100, width: 180, borderRadius: 2 }}
                    >
                      <AsyncImage
                        style={{ height: 100, width: 180, borderRadius: 2 }}
                        src={
                          item.path
                            ? `${process.env?.REACT_APP_AWS_PATH_BUCKET}` +
                              item.path
                            : item.imageSrc
                        }
                        alt=""
                      />
                      <DeleteIcon
                        style={{ top: -30, position: "relative" }}
                        onClick={() => {
                          saveRemoveImg(item);
                          deleteItem(item);
                        }}
                        color={"warning"}
                      />
                    </div>
                  ))}
                </Carousel>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    // overflow-x: auto;
                    // overflow-y: hidden;
                    // white-space: nowrap;
                  }}
                >
                  {images.length < 5 && (
                    <Button
                      color={"secondary"}
                      sx={{
                        maxWidth: 200,
                        borderRadius: 20,
                        fontFamily: "mplus-1c-bold",
                      }}
                      component="label"
                      variant="outlined"
                      onClick={handleImageClick}
                    >
                      {T("upload_images_txt")}
                      {/* <input
                        accept=".png, .jpeg, .jpg"
                        type="file"
                        hidden
                        onChange={onImageChange}
                      /> */}
                    </Button>
                  )}
                </div>
              </>
            )}

            {/* end */}

            {(user.client_id === "CL002" || user.client_id === "CL016") && (
              <>
                <Stack
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {images.length === 0 && (
                    <Typography
                      // color={imageError ? "red" : "black"}
                      fontFamily={"mplus-1c-bold"}
                      sx={{ textDecoration: "underline", marginBottom: -2 }}
                    >
                      {T("Upload_Images")}
                    </Typography>
                  )}
                </Stack>
                <div style={{ display: "flex", overflow: "scroll" }}>
                  {images.map((item) => (
                    <div style={{ paddingRight: 1 }}>
                      <AsyncImage
                        style={{ height: 100, width: 180, borderRadius: 2 }}
                        src={
                          item.path
                            ? `${process.env?.REACT_APP_AWS_PATH_BUCKET}` +
                              item.path
                            : item.imageSrc
                        }
                        alt=""
                      />
                      {/* <IconButton onClick={() => deleteItem(item)} aria-label="delete"> */}
                      <DeleteIcon
                        style={{ top: -30, position: "relative" }}
                        onClick={() => {
                          saveRemoveImg(item);
                          deleteItem(item);
                        }}
                        color={"warning"}
                      />
                      {/* </IconButton> */}
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {images.length < 5 && (
                    <Button
                      color={"secondary"}
                      sx={{
                        maxWidth: 200,
                        borderRadius: 20,
                        fontFamily: "mplus-1c-bold",
                      }}
                      component="label"
                      variant="outlined"
                    >
                      {T("upload_images_txt")}
                      <input
                        accept=".png, .jpeg, .jpg"
                        type="file"
                        hidden
                        onChange={onImageChange}
                      />
                    </Button>
                  )}
                </div>
              </>
            )}

            {/* //// */}
            <Stack
              spacing={2}
              style={{ backgroundColor: colors.farm_gray10, padding: 10 }}
            >
              <span style={{ height: 10 }}></span>
              <span
                style={{
                  marginBottom: -10,
                  fontFamily: "mplus-1c-bold",
                  borderBottom: 1,
                  marginTop: 20,
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.farm_gray10,
                }}
              >
                投票を設定しますか？
                {acceptVotes === "Yes" && (
                  <span style={{ color: colors.farm_red600 }}> *&nbsp;</span>
                )}
              </span>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={acceptVotes}
                onChange={handleAcceptVotes}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  label={T("Yes")}
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label={T("No")}
                />
              </RadioGroup>
              {acceptVotes === "Yes" && (
                <div>
                  <div style={votesCount > 0 ? { pointerEvents: "none" } : {}}>
                    <Stack
                      sx={{
                        width: "96%",
                      }}
                    >
                      {votingContent.map((_content: any, index: number) => (
                        <div>
                          <span
                            style={{
                              fontFamily: "mplus-1c-regular",
                              paddingLeft: 20,
                              paddingTop: 10,
                            }}
                          >
                            {T("Vote_Option")} {index + 1}
                          </span>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <TextField
                              error={
                                index === 0
                                  ? vote1Error
                                  : index === 1
                                  ? vote2Error
                                  : false
                              }
                              helperText={
                                index === 0
                                  ? vote1Error
                                    ? "* fields are required."
                                    : ""
                                  : (index === 1 ? vote2Error : false)
                                  ? "* fields are required."
                                  : ""
                              }
                              sx={{ paddingLeft: 2, width: "100%" }}
                              hiddenLabel
                              inputProps={{ maxLength: MAX_VOTE_LENGTH }}
                              value={getValue(index)}
                              onChange={(val) => {
                                addValue(val?.target?.value, index);
                              }}
                            />
                            {index > 1 && !editPost ? (
                              <DeleteIcon
                                style={{
                                  left: 6,
                                  top: 16,
                                  position: "relative",
                                }}
                                onClick={() => {
                                  delVote(index);
                                }}
                                color={"warning"}
                              />
                            ) : (
                              <div style={{ width: 26 }}></div>
                            )}
                          </div>
                        </div>
                      ))}
                    </Stack>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      {votingContent.length < 6 && (
                        <Button
                          onClick={() => addContentField()}
                          color={"secondary"}
                          sx={{
                            maxWidth: 200,
                            borderRadius: 20,
                            fontFamily: "mplus-1c-bold",
                          }}
                          component="label"
                          variant="outlined"
                        >
                          {T("Add_voting_Option")}
                        </Button>
                      )}
                    </div>

                    <RadioGroup
                      row
                      aria-labelledby="participants-controlled-radio-buttons-group"
                      name="participants-radio-buttons-group"
                      value={postPeriod}
                      onChange={handlePostPeriod}
                      style={{ fontFamily: "mplus-1c-bold" }}
                    >
                      {/* <FormControlLabel
                      value="90-days"
                      sx={{ fontFamily: "mplus-1c-bold" }}
                      control={<Radio />}
                      label={`90 ${T("days")} ${moment()
                        .add(90, "days")
                        .calendar()}`}
                    /> */}
                      <span
                        style={{
                          marginBottom: 10,
                          fontFamily: "mplus-1c-bold",

                          marginTop: 20,
                        }}
                      >
                        投票終了日 &nbsp;
                        <span style={{ color: colors.farm_gray200 }}>
                          最大３ヶ月先まで。
                        </span>
                      </span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={
                            dateError
                              ? {
                                  borderColor: colors.farm_red600,
                                  borderWidth: 1,
                                  borderStyle: "solid",
                                  borderRadius: 2,
                                }
                              : {}
                          }
                          minDate={dayjs(moment().format())}
                          maxDate={dayjs(moment().add(90, "days").calendar())}
                          value={postEndDate}
                          onAccept={(newValue) => {
                            handleDate(newValue || undefined);
                          }}
                        />
                        {dateError && (
                          <FormHelperText sx={{ color: colors.farm_red600 }}>
                            {T("fields_are_required")}
                          </FormHelperText>
                        )}
                      </LocalizationProvider>
                    </RadioGroup>
                  </div>
                </div>
              )}
            </Stack>
            <Stack spacing={2} style={{ padding: 10 }}>
              <span
                style={{
                  marginBottom: -10,
                  fontFamily: "mplus-1c-bold",
                  borderBottom: 1,
                  marginTop: 20,
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.farm_gray10,
                }}
              >
                {T("Do_you_accept_comments")}
                <span style={{ color: colors.farm_red600 }}> *&nbsp;</span>
              </span>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={accept}
                onChange={handleAccept}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  label={T("Yes")}
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label={T("No")}
                />
              </RadioGroup>
            </Stack>
            <Stack
              spacing={2}
              style={{
                backgroundColor: colors.farm_gray10,
                padding: 10,
                width: "100%",
              }}
            >
              <span style={{ height: 10 }}></span>
              <span
                style={{
                  marginBottom: -10,
                  fontFamily: "mplus-1c-bold",
                  borderBottom: 1,
                  marginTop: 20,
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.farm_gray100,
                }}
              >
                投稿名を選択してください。
                <span style={{ color: colors.farm_red600 }}> *&nbsp;</span>
                <br></br>
                <span style={{ color: colors.farm_gray200 }}>
                  匿名を選択した場合「匿名」と表示されます。
                </span>
              </span>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={anonymous}
                onChange={handleAnonymous}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={"ニックネーム"}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label={"本名"}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label={"匿名"}
                />
              </RadioGroup>
            </Stack>
            <Stack spacing={2} style={{ padding: 10, width: "100%" }}>
              <span style={{ height: 10 }}></span>
              <span
                style={{
                  marginBottom: -10,
                  fontFamily: "mplus-1c-bold",
                  borderBottom: 1,
                  marginTop: 20,
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.farm_gray100,
                }}
              >
                {T("Notes")}
                <span style={{ color: colors.farm_red600 }}> *&nbsp;</span>
              </span>
              <FormGroup>
                <Typography>{T("This_is_not_targeted_slander")}</Typography>
                <Typography>{T("The_content_and_recruitment")}</Typography>
                <Typography>{T("I_am_responsible")}</Typography>

                <FormControlLabel
                  color="error"
                  sx={{ color: agreeError ? colors.farm_red600 : "black" }}
                  control={
                    <Checkbox onChange={handleAgreeChange} value={agree} />
                  }
                  label={T("I_have_reviewed_the")}
                />
                {agreeError && (
                  <FormHelperText sx={{ color: colors.farm_red600 }}>
                    {T("fields_are_required")}
                  </FormHelperText>
                )}
              </FormGroup>
              <Button
                onClick={() => post()}
                disabled={disabled}
                style={{
                  alignSelf: "center",
                  borderRadius: 20,
                  backgroundColor: colors.farm_blue200,
                }}
                size={"large"}
                variant="contained"
                endIcon={<FontAwesomeIcon icon={faChevronRight} />}
              >
                <span style={{ minWidth: 200 }}>{T("Post")}</span>
              </Button>
            </Stack>
            <span style={{ height: 100 }}></span>
          </Stack>
          <LoadingModal visible={visible} handleClose={() => console.log("")} />
          <Snackbar
            open={snake !== ""}
            autoHideDuration={3000}
            sx={{ width: "94%" }}
            onClose={() => setSnake("")}
          >
            <Alert
              onClose={() => setSnake("")}
              severity="success"
              sx={{ width: "100%" }}
            >
              {snake}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            style={styles.introSliderImg}
            src={require("../assets/sliderimages/community_logo.png")}
            alt=""
          />
          <p>登録ユーザーのみが投稿を作成できます</p>
        </div>
      )}
    </div>
  );
}
export type ImageItem = {
  name: string | undefined;
  file: any;
  imageSrc: string;
  path: string | undefined;
  _destroy: string | undefined;
};
export type VotingContent = {
  inputText: string | undefined;
};
