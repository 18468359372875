import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import appServices from "../../services/app-services/appServices";

const initialState: any = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  postTypeOne: [], //facilitator posts
  postTypeZero: [], //every ones posts
};

export const getDashboard = createAsyncThunk(
  "posts/dashboard",
  async (_, thunkAPI) => {
    try {
      return await appServices.getDashboard();
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dashboardReducer = createSlice({
  name: "posts/dashboard",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.postTypeOne = action.payload.one;
        state.postTypeZero = action.payload.zero;
      })
      .addCase(getDashboard.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { reset } = dashboardReducer.actions;

export default dashboardReducer.reducer;
