// import { Breadcrumbs, ListItemButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import VoteIcon from "../assets/images/svg/vote_black.svg";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SeeMoreList from "../components/lists/SeeMoreList";
// import { T } from "react-translator-component";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import LoadingModal from "../components/LoadingModal";
import { getTopPosts } from "../redux/Reducers/postReducer";
import { Box } from "@mui/material";
import { colors } from "../common/colors";
import authService from "../services/app-services/auth-service";

export default function VotingSeeMorePage() {
  const dispatch = useAppDispatch();
  // const posts = useLocation().state;
  const { isLoadingTopPosts, topPosts } = useSelector(
    (state: any) => state.posts
  );
  const { user } = useSelector((state: any) => state.userAuth);
  const navigate = useNavigate();
  useEffect(() => {
    apiCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const apiCalls = async () => {
    try {
      dispatch(getTopPosts(1));
    } catch (error) {}
  };
  console.log(topPosts);
  useEffect(() => {
    const PortalAPP = window.PortalAPP;
    PortalAPP?.GetPersonalBasicInfo("B0000009-APP001", "めぶくコミュニティ");
    // eslint-disable-next-line
    function portalAppGetPersonalBasicInfoCallBack(result: any) {
      if (result.result_code === 200) {
        let body = {
          user: {
            name: result?.name,
          },
        };
        if (user?.id) authService.updateAppUser(user?.id, body);
      }
    }
    try {
      (window as any).portalAppGetPersonalBasicInfoCallBack =
        portalAppGetPersonalBasicInfoCallBack;
    } catch (error) {}

    // eslint-disable-next-line
  }, [user]);

  return (
    <div style={{ marginTop: 80, overflow: "scroll" }}>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <ListItemButton onClick={() => navigate(-1)} dense>
          <Typography color="text.primary">{T("Home")}</Typography>
        </ListItemButton>

        <Typography color="text.primary">{T("see_more")}</Typography>
      </Breadcrumbs> */}
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 16,
          display: "flex",
        }}
      >
        <LoadingModal
          visible={isLoadingTopPosts}
          handleClose={() => console.log("onclose")}
        />
        {/* <img height={40} src={VoteIcon} alt="" /> */}

        <Box
          sx={{
            backgroundColor: colors.farm_green500,
            padding: 1,
            paddingLeft: 4,
            paddingRight: 4,
            borderRadius: 2,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            borderColor: colors.farm_yellow10,
            borderWidth: 2,
            borderStyle: "solid",
          }}
        >
          <EmojiEventsIcon style={{ color: "#FFF", fontSize: 34 }} />
          <span
            style={{
              fontSize: 24,
              fontFamily: "mplus-1c-regular",
              paddingLeft: 6,
              color: "white",
            }}
          >
            総合ランキング
          </span>
        </Box>
      </div>

      <SeeMoreList
        onClickItem={(item) => navigate("/detailsview", { state: item })}
        items={topPosts || []}
      />
    </div>
  );
}
