import * as React from "react";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import profile from "../assets/images/profile_img.png";
import { LanguageList } from "react-translator-component";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import "bootstrap/dist/css/bootstrap.min.css";
import VerticalList from "../components/lists/VerticalList";
import "../App.css";
import { useState } from "react";
import appServices from "../services/app-services/appServices";
import LoadingModal from "../components/LoadingModal";

import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import { T } from "react-translator-component";
import { Button, colors, Stack } from "@mui/material";
import { useSelector } from "react-redux";
export default function MyFarm() {
  const { user } = useSelector((state: any) => state.userAuth);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [community, setCommunityPosts] = useState<any>([]);
  const [communityPagination, setCommunityPostsPagination] = useState<any>({});
  const [commentHistory, setCommentHistory] = useState<any>([]);
  const [commentHistoryPagination, setCommentHistoryPagination] = useState<any>(
    {}
  );
  // const [userImage, setUserImage] = useState<any>();
  // const [userImagee, setUserImagee] = useState<any>();
  React.useEffect(() => {
    if (user?.id) {
      apiCalls();
      apiCallsReject();
    }
    if (user.image) {
      // let base64String = user.image;
      // const invalidCharsRegex = /[^A-Za-z0-9+/=]/g;
      // // Replace invalid characters including the '+' sign with an empty string
      // const cleanBase64String = base64String.replace(invalidCharsRegex, "");
      // setUserImagee(cleanBase64String);
      // setUserImage(imageData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const apiCalls = async () => {
    setVisible(true);
    const posts = await appServices.getHistoryPosts(user.id, 1);

    setCommunityPosts(posts.posts);
    setCommunityPostsPagination(posts.pagination);
    setVisible(false);
  };

  const handleLangage = () => {
    navigate(0);
  };
  const handleMore = async () => {
    if (communityPagination?.next_page > 0) {
      setVisible(true);
      const posts = await appServices.getHistoryPosts(
        user.id,
        communityPagination?.next_page
      );
      setVisible(false);
      const newItems = [...community, ...posts.posts];
      setCommunityPosts(newItems);
      setCommunityPostsPagination(posts.pagination);
    }
  };

  const handleMoreComment = async () => {
    if (commentHistoryPagination?.next_page > 0) {
      setVisible(true);
      const posts = await appServices.getCommentHistoryPosts(
        user.id,
        commentHistoryPagination?.next_page
      );
      setVisible(false);
      const newItems = [...commentHistory, ...posts.posts];
      setCommentHistory(newItems);
      setCommentHistoryPagination(posts.pagination);
    }
  };

  const apiCallsReject = async () => {
    setVisible(true);
    const temp = await appServices.getCommentHistoryPosts(user.id, 1);
    // console.log(rejectPosts.posts, "Reject Postsssssssssssssss");
    setCommentHistory(temp.posts);
    setCommentHistoryPagination(temp.pagination);
    setVisible(false);
  };
  return (
    <React.Fragment>
      <div style={{ ...styles.farmContainer, padding: 0 }}>
        <LoadingModal
          visible={visible}
          handleClose={() => console.log("here")}
        />
        <LanguageList onChange={handleLangage} Theme="Dropdown" />
        <div style={{ ...styles.farmProfileInfo, flexDirection: "column" }}>
          <img
            src={
              user.image !== null
                ? "data:image/jpeg;base64," + user.image
                : profile
            }
            alt={"userimg"}
            style={styles.farmProfileIcon}
          />

          <span style={styles.farmInfoTitle}>{user.nickname}</span>
          {user?.id_kind === 2 && (
            <span
              style={{
                fontSize: 10,
                color: colors.red[400],
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              temp user
            </span>
          )}
        </div>

        <div>
          <Divider style={styles.farmDivider} />
          <Accordion
            variant={"elevation"}
            className=""
            style={styles.farmAccordion}
          >
            <AccordionSummary
              sx={{ color: "black" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color={"black"}>{T("Post_History")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color={"black"}>
                {community.length} {T("Pieces")}
              </Typography>
              {
                <>
                  <VerticalList status items={community} />
                  {communityPagination?.next_page > 0 && (
                    <Stack
                      sx={{
                        width: "100%",
                        color: "grey.500",
                        justifyContent: "center",
                      }}
                      spacing={2}
                    >
                      <Button onClick={handleMore} size="small">
                        {T("Load_More")}
                      </Button>
                    </Stack>
                  )}
                </>
              }
            </AccordionDetails>
          </Accordion>
          <Divider style={styles.farmDivider2} />
        </div>

        <div>
          <Accordion style={styles.farmAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{T("Comment_History")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color={"black"}>
                {commentHistory.length} {T("Pieces")}
              </Typography>

              <VerticalList items={commentHistory} />
              {commentHistoryPagination?.next_page > 0 && (
                <Stack
                  sx={{
                    width: "100%",
                    color: "grey.500",
                    justifyContent: "center",
                  }}
                  spacing={2}
                >
                  <Button onClick={handleMoreComment} size="small">
                    {T("Load_More")}
                  </Button>
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>
          <Divider style={styles.farmDivider2} />
        </div>

        {/* <div>
          <Accordion style={styles.farmAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{T("Number_of_Likes_Received")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  {T("Posts")}: <span>1538</span>
                </span>
                <span>
                  {T("Comments")} <span>285</span>
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
          <Divider style={styles.farmDivider2} />
        </div> */}

        {/* <div>
          <Accordion
            variant={"elevation"}
            className=""
            style={styles.farmAccordion}
          >
            <AccordionSummary
              style={{ color: "black", alignItems: "center" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color={"black"}>
            
                {T("Reject")}&nbsp;{T("Post")}
              </Typography>
              {rejectPosts.length === 0 ? null : (
                <Badge
                  color="error"
                  style={{ marginLeft: 30, alignSelf: "center" }}
                  badgeContent={rejectPosts.length}
                ></Badge>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Typography color={"black"}>
                {rejectPosts.length} {T("Pieces")}
                {",  これらの投稿には暴言が含まれる可能性があります。 "}
              </Typography>
              <VotingHorizontalList
                items={rejectPosts}
                title={""}
                onClickItem={(item) =>
                  navigate("/detailsview", { state: item })
                }
                type={"voting"}
                showSeeMore={false}
              />
            </AccordionDetails>
          </Accordion>
          <Divider style={styles.farmDivider2} />
        </div> */}

        <div>
          <Accordion style={styles.farmAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{T("Questionnaire")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  onClick={(event) => {
                    const target = event.target as HTMLElement;
                    if (target.tagName === "A") {
                      event.preventDefault();
                      const url = (target as HTMLAnchorElement).href;
                      console.log("Link clicked:", url);
                      // You can add additional logic here, such as opening the URL in a new tab/window
                      //window.open(url, "_system");
                      try {
                        const messageData = {
                          url: url,
                          condition: true,
                        };
                        window.parent.postMessage(
                          { type: "url", data: messageData },
                          "*"
                        );
                      } catch (error) {}
                    }
                  }}
                >
                  <a href="https://forms.office.com/r/7fnxwKXWRW">
                    https://forms.office.com/r/7fnxwKXWRW
                  </a>
                </p>
                <Divider style={styles.farmDivider3} />
                {/* <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Answer1" />
                  <FormControlLabel control={<Checkbox />} label="Answer2" />
                  <FormControlLabel control={<Checkbox />} label="Answer3" />
                  <FormControlLabel control={<Checkbox />} label="Answer4" />
                </FormGroup> */}
              </div>
            </AccordionDetails>
            {/* <AccordionDetails>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{T("Question_title")}</span>
                <Divider style={styles.farmDivider3} />
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Answer1" />
                  <FormControlLabel control={<Checkbox />} label="Answer2" />
                  <FormControlLabel control={<Checkbox />} label="Answer3" />
                  <FormControlLabel control={<Checkbox />} label="Answer4" />
                </FormGroup>
              </div>
            </AccordionDetails> */}
          </Accordion>
          <Divider style={styles.farmDivider4} />
        </div>
      </div>
    </React.Fragment>
  );
}
