import { uploadFile } from 'react-s3';
import AWS from 'aws-sdk';
window.Buffer = window.Buffer || require("buffer").Buffer; 


const config = {
    bucketName: process.env.REACT_APP_AWS_NAME_BUCKET,
    region: process.env.REACT_APP_AWS_REGION_JP,
    accessKeyId:process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
}



    const Upload = async (file:any) => {
        try {
            const response = uploadFile(file, config);
            console.log(response,'here')
            return response;
        } catch (error) {
            console.log(error,'hereerror')
            return error
        }
      
           
    }

    const uploadFileAWS = async (file:any) => {
        const S3_BUCKET =config.bucketName;
        const REGION = config.region;
    
        AWS.config.update({
          accessKeyId: config.accessKeyId,
          secretAccessKey:config.secretAccessKey,
          
          
        });
        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION,
        });
    
        const params = {
          Bucket: S3_BUCKET||'',
          Key: Date.now().toString()+file.name,
          Body: file,
        };
    
        var upload = s3
          .putObject(params)
          
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + ((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();
    
        await upload.then(res=>{
         
            return res;
            
        },rej=>{
console.log(rej)
        });

        return upload;
      };

    const s3UploadFiles = {
        Upload,
        uploadFileAWS
    };
    
    export default s3UploadFiles;
